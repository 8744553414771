import React from "react";

import { Button } from "../../../../components/_wrapper/Button";
import { Tooltip } from "../../../../components/_wrapper/Tooltip";
import "./Propose-Candidate-Item.css";

export interface ProposeCandidateItemProps {
  internalId: number;
  employeeId: number;
  employeeName: string;
  level: string;
  role: string;
  specialization: string;
  freepoolStatus: string;
  isInFullfillment?: boolean;
  onClick?: () => void;
}

export const ProposeCandidateItem: React.FC<ProposeCandidateItemProps> = ({
  internalId,
  employeeId,
  employeeName,
  level,
  role,
  specialization,
  freepoolStatus,
  isInFullfillment = false,
  onClick,
}) => {
  return (
    <div
      className={`propose-candidate-item ${isInFullfillment ? "selected" : ""}`.trim()}
    >
      <div className="propose-candidate-item-label">
        <span className="text-wrapper-1">{employeeId}</span> -{" "}
        <Tooltip
          title={employeeName}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          {employeeName}
        </Tooltip>
        <br />
        {level} | {role} | {specialization}
      </div>
      <div>
        <span className="selected-candidate-status">{freepoolStatus}</span>
      </div>
      {isInFullfillment && (
        <div className="selected-candidate-action">
          <Button type="link" onClick={onClick}>
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};
