import React, { Fragment, useState } from "react";
import { ReactComponent as ReportIcon } from "../../assets/bar-chart-2.svg";
import { ReactComponent as OpportunityProjectIcon } from "../../assets/briefcase.svg";
import { ReactComponent as DashboardIcon } from "../../assets/dashboard.svg";
import { ReactComponent as DemandDraftIcon } from "../../assets/demand-draft.svg";
import { ReactComponent as DemandOpenIcon } from "../../assets/demand-open.svg";
import { ReactComponent as PlusCircle } from "../../assets/plus-circle.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import { ReactComponent as EmployeeIcon } from "../../assets/team.svg";
import { useAppSelector } from "../../hooks/redux-hooks";
import { Demand } from "../../modules";
import { EModuleCode } from "../../views/enums";
import { IEmployeeProfile } from "../../views/interfaces";
import { Button, Drawer, Tooltip } from "../_wrapper";
import { SidebarMenuItem } from "../Sidebar-Menu-Item";
import { SVGIcon } from "../SVG-Icon";
import "./Sidebar-Menu.css";

export const sidebarConfig: {
  code: EModuleCode;
  SVGIconElement: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  routeUrl: string;
}[] = [
  {
    code: EModuleCode.M_DASHBOARD,
    SVGIconElement: DashboardIcon,
    routeUrl: "/dashboard",
  },
  {
    code: EModuleCode.M_DRAFT_DEMANDS,
    SVGIconElement: DemandDraftIcon,
    routeUrl: "/draft-demand",
  },
  {
    code: EModuleCode.M_OPEN_DEMANDS,
    SVGIconElement: DemandOpenIcon,
    routeUrl: "/open-demand",
  },
  {
    code: EModuleCode.M_EMPLOYEE,
    SVGIconElement: EmployeeIcon,
    routeUrl: "/employee",
  },
  {
    code: EModuleCode.M_DASHBOARD,
    SVGIconElement: OpportunityProjectIcon,
    routeUrl: "/opportunities-projects",
  },
  {
    code: EModuleCode.M_REPORTS,
    SVGIconElement: ReportIcon,
    routeUrl: "/report",
  },
  {
    code: EModuleCode.M_DASHBOARD,
    SVGIconElement: SettingsIcon,
    routeUrl: "/setting",
  },
  {
    code: EModuleCode.M_CREATE_DEMAND,
    SVGIconElement: DashboardIcon,
    routeUrl: "/create-demand",
  },
];

export interface SidebarMenuProps {
  menuList: IEmployeeProfile["moduleList"];
  changeActive: (code: string) => void;
  activeMenuCode: string;
}

export const SidebarMenu: React.FC<SidebarMenuProps> = ({
  menuList,
  changeActive,
  activeMenuCode,
}) => {
  const { sidebarCollapsed } = useAppSelector((state) => state.ui);
  const [openDemandForm, setOpenDemandForm] = useState<boolean>(false);

  return (
    <Fragment>
      <div
        className={`sidebar-menu ${
          sidebarCollapsed ? "sidebar-menu-only-icon" : ""
        }`.trim()}
      >
        <div
          className={`create-demand-button ${sidebarCollapsed ? "collapsed" : ""}`.trim()}
        >
          {sidebarCollapsed ? (
            <Tooltip title="Create Demand" placement="right" arrow={true}>
              <span onClick={() => setOpenDemandForm(true)}>
                <SVGIcon SVGElementIcon={PlusCircle} color="#ffffff" />
              </span>
            </Tooltip>
          ) : (
            <Button
              size="large"
              type="primary"
              icon={<SVGIcon SVGElementIcon={PlusCircle} color="#ffffff" />}
              style={{ width: "100%" }}
              onClick={() => setOpenDemandForm(true)}
              disabled={
                menuList.findIndex(
                  (item) => item.code == EModuleCode.M_CREATE_DEMAND
                ) === -1
              }
            >
              Create Demand
            </Button>
          )}
        </div>
        {menuList
          .filter((item) => item.isSideMenu)
          .map((item, index) => (
            <SidebarMenuItem
              key={index}
              code={item.code}
              title={item.name}
              SVGIconElement={
                sidebarConfig.find((config) => config.code === item.code)
                  ?.SVGIconElement ?? DashboardIcon
              }
              activeMenuCode={activeMenuCode}
              changeActive={changeActive}
            />
          ))}
      </div>
      <Drawer
        title="New Demand Form"
        open={openDemandForm}
        onClose={() => setOpenDemandForm(false)}
        width={840}
        children={
          <Demand type="new" closeForm={() => setOpenDemandForm(false)} />
        }
      />
    </Fragment>
  );
};
