import React, { CSSProperties, ReactNode } from "react";

import type { PortalProps } from "@rc-component/portal";
import type { DrawerProps as AntdDrawerProps } from "antd";
import AntdDrawer from "antd/es/drawer";
import { DrawerHeader } from "../Drawer-Header";
import "./Drawer.css";

export interface DrawerProps {
  open: boolean;
  onClose: any;
  children: ReactNode;
  title?: string;
  placement?: AntdDrawerProps["placement"];
  closable?: boolean;
  width?: string | number;
  height?: string | number;
  showSearchHeader?: boolean;
  searchHeaderState?: boolean;
  searchHeaderAction?: (state: boolean) => void;
  enableCloseHeader?: boolean;
  showCloseButtonOnLeftHeader?: boolean;
  setSearchString?: any;
  className?: string;
  rootClassName?: string;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
  destroyOnClose?: boolean;
  push?: boolean;
  mask?: boolean;
  getContainer?: PortalProps["getContainer"];
}

export const Drawer: React.FC<DrawerProps> = ({
  title = "Basic Drawer",
  placement = "right",
  closable = false,
  open = false,
  onClose,
  width = 560,
  height = "100%",
  children,
  showSearchHeader = false,
  searchHeaderState = false,
  searchHeaderAction = (state: boolean) => {},
  enableCloseHeader = true,
  showCloseButtonOnLeftHeader = false,
  setSearchString,
  className,
  rootClassName,
  style,
  rootStyle,
  destroyOnClose = true,
  push = false,
  mask = true,
  getContainer,
}) => {
  return (
    <AntdDrawer
      title={title}
      placement={placement}
      closable={closable}
      onClose={onClose}
      open={open}
      key={placement}
      width={width}
      height={height}
      maskClosable={false} // Disable closing on outside click
      className={className}
      rootClassName={rootClassName}
      style={style}
      rootStyle={rootStyle}
      push={push}
      mask={mask}
      destroyOnClose={destroyOnClose}
      getContainer={getContainer}
    >
      <DrawerHeader
        headerLabel={title}
        showSearch={showSearchHeader}
        enableSearch={searchHeaderState}
        setEnableSearch={searchHeaderAction}
        enableClose={enableCloseHeader}
        showCloseButtonOnLeft={showCloseButtonOnLeftHeader}
        closeDrawer={onClose}
        setSearchString={setSearchString}
        style={{
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #fe5000",
        }}
      />
      <div className="drawer-body-content">{children}</div>
    </AntdDrawer>
  );
};
