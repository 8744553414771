import { useMsal } from "@azure/msal-react";
import React from "react";
import loginBackgroundVideo from "../../assets/login.mp4";
import "./Auth.css";
import { AuthCard } from "./components/Auth-Card";
import { loginRequest } from "./config";

export const Auth: React.FC = () => {
  const { instance } = useMsal();

  const handleSignIn = async () => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error("SignIn Error: ", e);
    });
  };

  return (
    <div className="tribe-sign-in">
      <video className="video" autoPlay muted controls={false} loop>
        <source src={loginBackgroundVideo} type="video/mp4" />
        Your browser doesn't support videos.
      </video>
      <div className="background">
        <div className="action">
          <AuthCard signIn={handleSignIn} />
        </div>
      </div>
    </div>
  );
};
