import { createAction, createReducer } from "@reduxjs/toolkit";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../utils/local-storage";

export interface IMaintenance {
  isInMaintenanceMode: boolean;
  isMaintenanceinFuture: boolean;
  startDate: string | null;
  endDate: string | null;
  message: string | null;
}

const maintenanceData = getLocalStorage("maintenance");

const initialState: IMaintenance = {
  isInMaintenanceMode: maintenanceData?.isInMaintenanceMode || false,
  isMaintenanceinFuture: maintenanceData?.isMaintenanceinFuture || false,
  startDate: maintenanceData?.startDate || null,
  endDate: maintenanceData?.endDate || null,
  message: maintenanceData?.message || null,
};

export const setMaintenanceMode = createAction(
  "setMaintenanceMode",
  (args: IMaintenance) => {
    return {
      payload: {
        isInMaintenanceMode: args.isInMaintenanceMode,
        isMaintenanceinFuture: args.isMaintenanceinFuture,
        startDate: args.startDate,
        endDate: args.endDate,
        message: args.message,
      },
    };
  }
);
export const resetMaintenanceMode = createAction("resetMaintenanceMode");

export const maintenanceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setMaintenanceMode, (state, action) => {
      state.isInMaintenanceMode = action.payload.isInMaintenanceMode;
      state.isMaintenanceinFuture = action.payload.isMaintenanceinFuture;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.message = action.payload.message;

      setLocalStorage("maintenance", action.payload);
    })
    .addCase(resetMaintenanceMode, (state, action) => {
      state.isInMaintenanceMode = false;
      state.isMaintenanceinFuture = false;
      state.startDate = null;
      state.endDate = null;
      state.message = null;

      removeLocalStorage("maintenance");
    });
});
