import { createAction, createReducer } from "@reduxjs/toolkit";
import {
  getSessionStorage,
  setSessionStorage,
} from "../../utils/session-storage";
import { IMsg, INotify, IUi } from "../../views/interfaces";

const uiData = getSessionStorage("ui");

const initialState: IUi = {
  loading: 0,
  sidebarCollapsed: uiData?.sidebarCollapsed || false,
  headerTitle: uiData?.headerTitle || "Tribe",
  avatar: uiData?.avatar || "",
  logout: false,
  notify: {
    placement: "bottomRight",
    duration: 4,
    show: false,
    type: "info",
    message: "",
    description: { content: "" },
    key: 0,
  },
  msg: {
    show: false,
    type: "success",
    content: "",
    duration: 2,
    key: 0,
  },
};

export const setSidebarCollapsedMode = createAction(
  "setSidebarCollapsedMode",
  (args: boolean) => {
    return {
      payload: {
        sidebarCollapsed: args,
      },
    };
  }
);

export const setHeaderTitle = createAction("setHeaderTitle", (args: string) => {
  return {
    payload: {
      headerTitle: args,
    },
  };
});

export const setAvatar = createAction("setAvatar", (args: string) => {
  return {
    payload: args,
  };
});

export const setLoading = createAction("setLoading", (args: boolean) => {
  return {
    payload: args,
  };
});

export const setLogout = createAction("setLogout", (args: boolean) => {
  return {
    payload: args,
  };
});
export const setNotification = createAction(
  "setNotification",
  (args: INotify) => {
    return {
      payload: args,
    };
  }
);

export const resetNotification = createAction("resetNotification");

export const setMessage = createAction("setMessage", (args: IMsg) => {
  return {
    payload: args,
  };
});

export const resetMessage = createAction("resetMessage");

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSidebarCollapsedMode, (state, action) => {
      state.sidebarCollapsed = action.payload.sidebarCollapsed;

      setSessionStorage("ui", state);
    })
    .addCase(setHeaderTitle, (state, action) => {
      state.headerTitle = action.payload.headerTitle;

      setSessionStorage("ui", state);
    })
    .addCase(setAvatar, (state, action) => {
      state.avatar = action.payload;

      setSessionStorage("ui", state);
    })
    .addCase(setLoading, (state, action) => {
      state.loading = action.payload
        ? state.loading + 1
        : state.loading <= 0
          ? 0
          : state.loading - 1;
    })
    .addCase(setLogout, (state, action) => {
      state.logout = action.payload;
    })
    .addCase(setNotification, (state, action) => {
      state.notify.show = action.payload.show;
      state.notify.type = action.payload.type
        ? action.payload.type
        : initialState.notify.type;
      state.notify.message = action.payload.message;
      state.notify.description = action.payload.description;
      state.notify.placement = action.payload.placement
        ? action.payload.placement
        : initialState.notify.placement;
      state.notify.duration =
        action.payload.duration || action.payload.duration === 0
          ? action.payload.duration
          : initialState.notify.duration;
      state.notify.key = action.payload.key;
    })
    .addCase(resetNotification, (state, action) => {
      state.notify.show = initialState.notify.show;
      state.notify.type = initialState.notify.type;
      state.notify.message = initialState.notify.message;
      state.notify.description = initialState.notify.description;
      state.notify.placement = initialState.notify.placement;
      state.notify.duration = initialState.notify.duration;
      state.notify.key = initialState.notify.key;
    })
    .addCase(setMessage, (state, action) => {
      state.msg.show = action.payload.show;
      state.msg.type = action.payload.type
        ? action.payload.type
        : initialState.msg.type;
      state.msg.content = action.payload.content;
      state.msg.duration = action.payload.duration
        ? action.payload.duration
        : initialState.msg.duration;
      state.msg.key = action.payload.key;
    })
    .addCase(resetMessage, (state, action) => {
      state.msg.show = initialState.msg.show;
      state.msg.type = initialState.msg.type;
      state.msg.content = initialState.msg.content;
      state.msg.duration = initialState.msg.duration;
      state.msg.key = initialState.msg.key;
    });
});
