import React from "react";
import tribeLogo from "../../../../assets/logo.jpg";
import { ReactComponent as MicrosoftLogoComponent } from "../../../../assets/microsoft.svg";
import { Button, SVGIcon } from "../../../../components";
import { MaintenanceMessage, MaintenanceMode } from "../../../../environment";
import "./Auth-Card.css";

export interface AuthCardProps {
  signIn: any;
}

export const AuthCard: React.FC<AuthCardProps> = ({ signIn }) => {
  return (
    <div className="login">
      <div className="tribe">
        <div className="logo-img">
          <img src={tribeLogo} width={32} height={32} alt="No Logo" />
        </div>
        <p className="welcome">
          <span className="text-wrapper-1">Welcome to </span>
          <span className="text-wrapper-2">tribe</span>
        </p>
      </div>
      <div className="frame">
        {MaintenanceMode ? (
          <div className="maintenance">
            <p className="text-wrapper-5">Maintenance Mode</p>
            <p className="text-wrapper-6">{MaintenanceMessage}</p>
          </div>
        ) : (
          <>
            <p className="text-wrapper-3">All your demands fulfilled here!</p>
            <div className="sign-in">
              <p className="text-wrapper-4">
                Sign in using Microsoft credentials
              </p>
              <Button
                htmlType="button"
                type="primary"
                size="small"
                onClick={signIn}
                className="sign-in-button"
                icon={
                  <SVGIcon
                    SVGElementIcon={MicrosoftLogoComponent}
                    color={MaintenanceMode ? "#929497" : "#fe5000"}
                  />
                }
                iconPosition="end"
                disabled={MaintenanceMode}
              >
                Sign In
              </Button>
            </div>
          </>
        )}
        <div className="copyrignts">©Copyrights Tredence 2022</div>
      </div>
    </div>
  );
};
