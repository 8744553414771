import {
  configureStore,
  isAsyncThunkAction,
  isFulfilled,
  isPending,
  isRejected,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { triggerMessageService } from "../services/toast-messages";
import { triggerNotificationService } from "../services/toast-notification";
import { graphReducer } from "./reducer/graph";
import { maintenanceReducer } from "./reducer/maintenance";
import { setLoading, setLogout, uiReducer } from "./reducer/ui";
import { dashboardReducer } from "./slice/dashboard";
import { demandReducer } from "./slice/demand";
import { draftDemandReducer, getDraftFilterData } from "./slice/draft-demand";
import { employeeReducer } from "./slice/employee";
import { openDemandReducer } from "./slice/open-demand";
import { reportReducer } from "./slice/report";

const isSilentAction = isAsyncThunkAction(getDraftFilterData);

export const genericLoaderMiddleware =
  (store: any) => (next: any) => (action: any) => {
    if (!isSilentAction(action)) {
      if (isPending(action)) {
        store.dispatch(setLoading(true));
      } else if (isFulfilled(action)) {
        triggerNotificationService(store, action);
        store.dispatch(setLoading(false));
      } else if (isRejected(action) || isRejectedWithValue(action)) {
        if (action?.error?.message?.includes("401")) {
          triggerMessageService(
            store,
            action,
            "Token Expired! Please logout and login again."
          );
          store.dispatch(setLogout(true));
        } else {
          triggerMessageService(store, action);
        }
        store.dispatch(setLoading(false));
      }
    }
    return next(action);
  };

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    graph: graphReducer,
    maintainance: maintenanceReducer,
    dashboard: dashboardReducer,
    demand: demandReducer,
    draftDemands: draftDemandReducer,
    openDemands: openDemandReducer,
    employee: employeeReducer,
    report: reportReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(genericLoaderMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
