import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { setLogout } from "../../redux/reducer/ui";
import { RootState } from "../../redux/store";
import { Avatar } from "../_wrapper/Avatar";
import { AntdMenuProps, Dropdown } from "../_wrapper/Dropdown";
import "./Header.css";

export interface HeaderProps { }

export const Header: React.FC<HeaderProps> = () => {
  const dispatch = useAppDispatch();
  const { headerTitle, avatar } = useAppSelector(
    (state: RootState) => state.ui
  );
  const { displayName } = useAppSelector((state: RootState) => state.graph);
  const [nameInitials, setNameInitials] = React.useState<string>("");

  useEffect(() => {
    displayName.split(" ").forEach((name) => {
      setNameInitials((prev) => prev + name.charAt(0));
    });
  }, [displayName]);

  const dropdownItems: AntdMenuProps["items"] = [
    // {
    //   key: "1",
    //   label: <p>Profile</p>,
    // },
    {
      key: "2",
      label: <p onClick={() => dispatch(setLogout(true))}>Logout</p>,
    },
  ];

  return (
    <header className="header">
      <div className="title">{headerTitle}</div>
      <div className="navbar">
        <div className="avatar">
          <Dropdown menu={{ items: dropdownItems }} placement="bottom">
            <div style={{ cursor: "pointer" }}>
              {avatar ? (
                <Avatar
                  size={"large"}
                  src={<img src={avatar} alt="avatar" />}
                ></Avatar>
              ) : (
                <Avatar size={"large"}>{nameInitials}</Avatar>
              )}
            </div>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};
