import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as chevronsDownComponent } from "../../assets/down.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as chevronsUpComponent } from "../../assets/up.svg";
import { sortCheckUncheckedFilter } from "../../utils";
import { IFilterOption } from "../../views/interfaces";
import { Button } from "../_wrapper/Button";
import { AntdMenuProps, Dropdown } from "../_wrapper/Dropdown";
import { Empty } from "../_wrapper/Empty";
import { SearchBar } from "../_wrapper/Search-Bar";
import { Tooltip } from "../_wrapper/Tooltip";
import { FilterOptionItem } from "../Filter-Option-Item";
import { SVGIcon } from "../SVG-Icon";
import "./Filter-Basic.css";

export interface FilterBasicProps {
  label: string;
  data: IFilterOption[];
  onChange?: (
    label: any,
    value: string,
    checked: boolean,
    filterLabel?: string
  ) => void;
  action?: (apply?: boolean, clear?: boolean) => void;
  hideCount?: boolean;
  filterType?: "group" | "item";
  is_searchable?: boolean;
}

export const FilterBasic: React.FC<FilterBasicProps> = ({
  label,
  data,
  onChange = (label, value, checked, filterLabel) => {},
  action = (apply = false) => {},
  hideCount = false,
  filterType = "item",
  is_searchable = false,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [filterData, setFilterData] = useState<IFilterOption[]>([]);
  const [searchString, setSearchString] = useState<string | null>(null);

  useEffect(() => {
    let filteredData: IFilterOption[] = [];
    if (searchString && searchString.length > 0) {
      filteredData = data.map((item) => {
        return {
          ...item,
          children:
            item?.children?.filter((child) =>
              child.label.toLowerCase().includes(searchString.toLowerCase())
            ) ?? [],
        };
      });
      filteredData = filteredData.filter((dt) => {
        return dt.children && dt.children.length > 0
          ? dt
          : dt.label.toLowerCase().includes(searchString.toLowerCase());
      });
    } else {
      filteredData =
        filterType == "item" ? sortCheckUncheckedFilter(data) : data;
    }
    setFilterData(filteredData);
  }, [searchString, data, filterType]);

  const setSelectedCountValue = useCallback(() => {
    setSelectedCount(
      filterData.filter((item) =>
        item.isUpdated ? item.tempChecked : item.checked || item.indeterminate
      ).length
    );
  }, [filterData]);

  useEffect(() => {
    setSelectedCountValue();
  }, [filterData, setSelectedCountValue]);

  const filterApply = () => {
    setSelectedCountValue();
    action(true);
    setOpen(false);
  };

  const handleOpenChange = (
    nextOpen: boolean,
    info: { source: "trigger" | "menu" }
  ) => {
    if (info.source === "trigger") {
      if (!nextOpen) {
        action(false);
        setOpen(nextOpen);
      }
    }
  };

  const onSelectionChange = useCallback(
    (label: any, value: string, checked: boolean, filterLabel: string) => {
      onChange(label, value, checked, filterLabel);
    },
    [onChange]
  );

  const items: AntdMenuProps["items"] = useMemo(() => {
    return filterData?.map((item, idx) => {
      return {
        key: (idx + 1).toString(),
        type: filterType,
        label: (
          <FilterOptionItem
            data={item}
            showCount={false}
            action={(label, value, checked) =>
              onSelectionChange(label, value, checked, item.label)
            }
            filterType={filterType}
          />
        ),
        children:
          filterType === "group"
            ? item?.children?.map((child, _idx) => {
                return {
                  key: `${item.label}-${_idx + 1}`,
                  label: (
                    <FilterOptionItem
                      data={child}
                      showCount={false}
                      action={(label, value, checked) =>
                        onSelectionChange(label, value, checked, item.label)
                      }
                      filterType={filterType}
                    />
                  ),
                };
              })
            : undefined,
      };
    });
  }, [filterData, filterType, onSelectionChange]);

  return (
    <div className="filter-basic">
      <Dropdown
        rootClassName="filter-basic-dropdown"
        trigger={["click"]}
        menu={{ items }}
        onOpenChange={handleOpenChange}
        open={open}
        destroyPopupOnHide={false}
        dropdownRender={(menu) => (
          <>
            {is_searchable && (
              <div className="filter-option-search">
                <SearchBar
                  rightIcon={SearchIcon}
                  rootStyle={{ width: "232px" }}
                  size="medium"
                  setSearchString={setSearchString}
                />
              </div>
            )}
            {items?.length > 0 ? (
              <>
                <div className="filter-basic-dropdown-list">
                  {React.cloneElement(menu as React.ReactElement)}
                </div>
                <div className="filter-option-apply-button">
                  <Button
                    size="small"
                    type="primary"
                    style={{ width: "200px" }}
                    onClick={filterApply}
                  >
                    Apply Filter
                  </Button>
                </div>
              </>
            ) : (
              <Empty />
            )}
          </>
        )}
      >
        <Button
          htmlType="button"
          type="text"
          size="small"
          className={`filter-basic-button ${
            !open && selectedCount > 0 ? "selected" : ""
          }`.trim()}
          onClick={() => setOpen(!open)}
          icon={
            <SVGIcon
              SVGElementIcon={
                open ? chevronsUpComponent : chevronsDownComponent
              }
              height={20}
              width={20}
            />
          }
          iconPosition="end"
        >
          <div>
            <Tooltip title={label} placement="bottomLeft">
              <span className="text-wrapper-1">{label}</span>
            </Tooltip>
            <span className="text-wrapper-2">
              {!open && selectedCount > 0 ? selectedCount : ""}
            </span>
          </div>
        </Button>
      </Dropdown>
    </div>
  );
};
