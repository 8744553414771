import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { downloadReportData } from "../../redux/slice/report";
import { getReportsService } from "../../services/reports";
import { EModuleCode } from "../../views/enums";
import { IReportsCard } from "../../views/interfaces";
import { ReportCard } from "./components/Report-Card";

export interface ReportsProps {}

export const Reports: React.FC<ReportsProps> = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.employee);
  const [data, setData] = useState<IReportsCard[]>([]);
  const [moduleId, setModuleId] = useState<number | undefined>(undefined);

  useMemo(() => {
    if (profile) {
      setModuleId(
        profile?.moduleList?.find(
          (module) => module.name === EModuleCode.M_REPORTS
        )?.id
      );
    }
  }, [profile]);

  useEffect(() => {
    const fetchData = async () => {
      const result = getReportsService();
      setData(result);
    };
    fetchData();
  }, []);

  const handleReportData = (url: string) => {
    dispatch(downloadReportData(url));
  };

  return (
    <div className="reports">
      {data?.map((data, index) => (
        <ReportCard data={data} key={index} getReport={handleReportData} />
      ))}
    </div>
  );
};
