import React, { Fragment, ReactNode } from "react";

import { InputNumber } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import AntdInput from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import "./Input.css";

export interface InputProps {
  htmlType?: "number" | "textarea" | "text" | "email";
  placeholder?: string;
  min?: number;
  max?: number;
  defaultValue?: number;
  size?: SizeType;
  changeOnWheel?: boolean;
  disabled?: boolean;
  rows?: number;
  showCount?: boolean;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  value?: any;
  onChange?: (value: any) => void;
  prefix?: ReactNode;
  suffix?: ReactNode;
  autoComplete?: "on" | "off";
}

export const Input: React.FC<InputProps> = ({
  htmlType = "text",
  placeholder = "Enter Number",
  min,
  max,
  defaultValue,
  size = "large",
  changeOnWheel = false,
  disabled = false,
  rows = 4,
  showCount = true,
  className,
  style = { width: "100%" },
  id,
  value,
  onChange,
  prefix,
  suffix,
  autoComplete = "off",
}) => {
  return (
    <Fragment>
      {htmlType == "number" ? (
        <InputNumber
          id={id}
          value={value}
          className={className}
          placeholder={placeholder}
          min={min}
          max={max}
          defaultValue={defaultValue}
          onChange={onChange}
          size={size}
          changeOnWheel={changeOnWheel}
          disabled={disabled}
          style={style}
          prefix={prefix}
          suffix={suffix}
          controls={false}
          autoComplete={autoComplete}
        />
      ) : htmlType == "textarea" ? (
        <TextArea
          id={id}
          value={value}
          onChange={onChange}
          className={className}
          rows={rows}
          placeholder={placeholder}
          maxLength={max}
          defaultValue={defaultValue}
          disabled={disabled}
          size={size}
          showCount={showCount}
          autoComplete={autoComplete}
        />
      ) : (
        <AntdInput
          type={htmlType}
          id={id}
          value={value}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
          size={size}
          defaultValue={defaultValue}
          disabled={disabled}
          style={style}
          prefix={prefix}
          suffix={suffix}
          autoComplete={autoComplete}
        />
      )}
    </Fragment>
  );
};
