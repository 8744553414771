import React, { CSSProperties, ReactNode, useId } from "react";

import { TreeSelect, TreeSelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { AntTreeNodeProps } from "antd/es/tree";
import { ReactComponent as DownIconComponent } from "../../../assets/down.svg";
import { ReactComponent as RightIconComponent } from "../../../assets/right.svg";
import { ReactComponent as SearchIconComponent } from "../../../assets/search.svg";
import { Tooltip } from "../Tooltip";
import "./Select-Tree.css";

export interface SelectTreeProps {
  placeholder: string | ReactNode;
  treeData: DefaultOptionType[];
  value?: string[];
  onChange?: (value: any) => void;
  treeCheckable?: ReactNode | boolean;
  showCheckedStrategy?: TreeSelectProps["showCheckedStrategy"];
  style?: CSSProperties;
  className?: string;
  rootClassName?: string;
  id?: string;
  showSearch?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  labelInValue?: boolean;
  defaultValue?: string[];
  multiple?: boolean;
  maxTagCount?: number | "responsive";
}

export const SelectTree: React.FC<SelectTreeProps> = ({
  treeData,
  value,
  onChange = (value) => {},
  treeCheckable = true,
  showCheckedStrategy = TreeSelect.SHOW_PARENT,
  placeholder = "Please select",
  style,
  className,
  rootClassName,
  id,
  showSearch = true,
  allowClear = true,
  disabled = false,
  labelInValue = true,
  defaultValue,
  multiple = false,
  maxTagCount = "responsive",
}) => {
  const selectId = useId();
  const [fieldClicked, setFieldClicked] = React.useState<boolean>(false);

  const onSelectionChange = (
    value: string[],
    labelList: React.ReactNode[],
    extra: any
  ) => {
    onChange(value);
  };

  const filterOption = (inputValue: string, treeNode: DefaultOptionType) => {
    if (treeNode?.children?.length && treeNode?.children?.length > 0) {
      return (
        treeNode?.children
          ?.map((child) => child.label.toString().toLowerCase())
          .includes(inputValue.toLowerCase()) ?? false
      );
    }
    return (
      treeNode?.label
        ?.toString()
        ?.toLowerCase()
        .includes(inputValue.toLowerCase()) ?? false
    );
  };

  return (
    <TreeSelect
      id={id || selectId}
      treeData={treeData}
      filterTreeNode={(inputValue, treeNode) =>
        filterOption(inputValue, treeNode as DefaultOptionType)
      }
      value={value}
      defaultValue={defaultValue}
      onChange={onSelectionChange}
      treeCheckable={treeCheckable}
      showCheckedStrategy={showCheckedStrategy}
      placeholder={placeholder}
      style={style}
      className={className}
      rootClassName={rootClassName}
      suffixIcon={
        fieldClicked ? <SearchIconComponent /> : <DownIconComponent />
      }
      onClick={() => setFieldClicked(!fieldClicked)}
      onBlur={() => setFieldClicked(false)}
      switcherIcon={(props: AntTreeNodeProps) => (
        <>{props.expanded ? <DownIconComponent /> : <RightIconComponent />}</>
      )}
      showSearch={showSearch}
      allowClear={allowClear}
      disabled={disabled}
      labelInValue={labelInValue}
      multiple={multiple}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={(omittedValues) => (
        <Tooltip title={omittedValues.map(({ label }) => label).join(", ")}>
          <span>{omittedValues.length} +</span>
        </Tooltip>
      )}
    />
  );
};
