import React, { Fragment, useState } from "react";
import { ReactComponent as InfoIconComponent } from "../../../../assets/info.svg";
import { ReactComponent as FeatureUnderConstruction } from "../../../../assets/dashboard-under-construction.svg";
import { Popover } from "../../../../components/_wrapper/Popover";
import { SVGIcon } from "../../../../components/SVG-Icon";
import { getNotActedDemandCardMappingService } from "../../../../services/dashboard";
import {
  IDashboardCard,
  IDashboardSelectedFilter,
} from "../../../../views/interfaces";
import { Link } from "react-router-dom";
import "./Dashboard-Card.css";
import { EPermissionType } from "../../../../views/enums";

export interface DashboardCardProps {
  data?: IDashboardCard;
  SubHeaderFilters?: IDashboardSelectedFilter;
}

export const popoverContent = (props: number[] = [0, 0]) => (
  <div>
    <p>
      <span>{props[0]}</span> Open Demands in Projects
    </p>
    <p>
      <span>{props[1]}</span> Open Demands in Opportunities
    </p>
  </div>
);

export const DashboardCard: React.FC<DashboardCardProps> = ({
  data = getNotActedDemandCardMappingService({}, {}),
  SubHeaderFilters = {
    account: [],
    vertical: [],
    practice: [],
    subVertical: [],
  },
}: DashboardCardProps) => {
  const centerLength = data.center?.length || 0;
  const [showUnderConstruction, setShowUnderConstruction] =
    useState<boolean>(false);
  // should be removed after mvp1
  const hideCard = data.top.label !== "Open Demands"; // as of now all cards are gonna be hidden under construction except Open Demands Card
  return (
    <div
      className="dashboard-card"
      data-testid="dashboard-card"
      onMouseEnter={() => setShowUnderConstruction(true)}
      onMouseLeave={() => setShowUnderConstruction(false)}
    >
      {hideCard &&
        showUnderConstruction && ( // should be removed after mvp1
          <div className="hide-card-container">
            <SVGIcon
              SVGElementIcon={FeatureUnderConstruction}
              width={"82.35px"}
              height={"45px"}
            />
            <p className="text-wrapper-value">
              This feature is under construction
            </p>
          </div>
        )}
      <>
        <div className="frame-top">
          <div className="content">
            <p className="text-wrapper-label">{data.top.label}</p>
            <p
              className={`text-wrapper-value ${hideCard ? "hide-content" : ""}`} // hide-content condition should be removed after mvp1
            >
              <Link
                to={data.top.url}
                state={{
                  additionalFilters: data.top.filter,
                  defaultFilters: SubHeaderFilters,
                }}
                className={
                  data.top.permission === EPermissionType.Read ||
                  data.top.value === 0 ||
                  hideCard
                    ? "disabled-link"
                    : ""
                }
              >
                {data.top.value}
              </Link>
            </p>
          </div>
          {centerLength > 0 && (
            <div className="content-center">
              <p className="text-wrapper-value ">
                {data.center?.map((item, idx) => (
                  <Fragment key={idx}>
                    <Link
                      to={item.url}
                      state={{
                        additionalFilters: item.filter,
                        defaultFilters: SubHeaderFilters,
                      }}
                      className={
                        item.permission === EPermissionType.Read ||
                        item.value === 0 ||
                        hideCard
                          ? "disabled-link"
                          : ""
                      }
                    >
                      {item.value}
                    </Link>
                    {idx !== centerLength - 1 ? " | " : ""}
                  </Fragment>
                ))}
              </p>
              <Popover
                content={popoverContent(data.center?.map((item) => item.value))}
              >
                <div>
                  <SVGIcon SVGElementIcon={InfoIconComponent} />
                </div>
              </Popover>
            </div>
          )}
        </div>
        <div className="frame-bottom">
          {data?.bottom.map((item, idx) => (
            <div className="content" key={idx}>
              <p className="text-wrapper-label">
                {item.label}
                {item.extra?.label ? ` | ${item.extra.label}` : ""}
              </p>
              <p
                className={`text-wrapper-value ${hideCard ? "hide-content" : ""}`} // hide-content condition should be removed after mvp1
              >
                <Link
                  to={item.url}
                  state={{
                    additionalFilters: item.filter,
                    defaultFilters: SubHeaderFilters,
                  }}
                  className={
                    item.permission === EPermissionType.Read ||
                    item.value === 0 ||
                    hideCard
                      ? "disabled-link"
                      : ""
                  }
                >
                  {item.value}
                </Link>
                {typeof item.extra?.value === "number" &&
                  item.extra?.value >= 0 && (
                    <>
                      {" | "}
                      <Link
                        to={item.extra.url}
                        state={{
                          additionalFilters: item.extra.filter,
                          defaultFilters: SubHeaderFilters,
                        }}
                        className={
                          item.extra.permission === EPermissionType.Read ||
                          item.extra.value === 0 ||
                          hideCard
                            ? "disabled-link"
                            : ""
                        }
                      >
                        {item.extra.value}
                      </Link>
                    </>
                  )}
              </p>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};
