import React, { Fragment } from "react";

import AntdBadge from "antd/es/badge";
import "./Badge.css";

export interface BadgeProps {
  count: number;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = ({
  count = 0,
  className,
  style,
  children,
}) => {
  return (
    <Fragment>
      {count > 0 ? (
        <AntdBadge count={count} className={className} style={style}>
          {children}
        </AntdBadge>
      ) : (
        <>{children}</>
      )}
    </Fragment>
  );
};
