import React from "react";
import { IFilterOption } from "../../views/interfaces";
import { Checkbox } from "../_wrapper/Checkbox";
import "./Filter-Option-Item.css";

export interface FilterOptionItemProps {
  data: IFilterOption;
  showCount: boolean;
  action?: (label: any, value: string, checked: boolean) => void;
  addExtraPaddingRight?: boolean;
  disabled?: boolean;
  filterType?: "group" | "item";
}

export const FilterOptionItem: React.FC<FilterOptionItemProps> = ({
  data,
  showCount = false,
  action = () => {},
  addExtraPaddingRight = false,
  disabled = false,
  filterType = "item",
}) => {
  return (
    <div
      className="filter-option-item"
      style={addExtraPaddingRight ? { paddingRight: 20 } : undefined}
    >
      <Checkbox
        label={data?.label}
        value={data?.value}
        checked={data?.isUpdated ? data?.tempChecked : data?.checked}
        indeterminate={data?.indeterminate}
        action={action}
        disabled={disabled || data?.disabled}
        checkboxType={filterType}
      />
      {showCount && (
        <span className="text-wrapper-1">
          {data?.count && data?.count > 99 ? "99+" : data?.count}
        </span>
      )}
    </div>
  );
};
