import React from "react";

import { Tooltip as AntdTooltip } from "antd";
import { PresetColorType } from "antd/es/_util/colors";
import { LiteralUnion } from "antd/es/_util/type";
import { AdjustOverflow, TooltipPlacement } from "antd/es/tooltip";
import type { placements as Placements } from "rc-tooltip/lib/placements";
import "./Tooltip.css";

export interface TooltipProps {
  title: string | React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  rootClassName?: string;
  color?: LiteralUnion<PresetColorType>;
  placement?: TooltipPlacement;
  builtinPlacements?: typeof Placements;
  openClassName?: string;
  arrow?:
    | boolean
    | {
        pointAtCenter?: boolean;
      };
  autoAdjustOverflow?: boolean | AdjustOverflow;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  children?: React.ReactNode;
  destroyTooltipOnHide?:
    | boolean
    | {
        keepParent?: boolean;
      };
}

export const Tooltip: React.FC<TooltipProps> = ({
  title,
  style,
  className,
  rootClassName,
  color,
  placement = "bottomLeft",
  builtinPlacements,
  openClassName,
  arrow = false,
  autoAdjustOverflow,
  getPopupContainer,
  children,
  destroyTooltipOnHide = true,
}) => {
  return (
    <AntdTooltip
      title={title}
      style={style}
      className={className}
      rootClassName={rootClassName}
      color={color}
      placement={placement}
      builtinPlacements={builtinPlacements}
      openClassName={openClassName}
      arrow={arrow}
      autoAdjustOverflow={autoAdjustOverflow}
      getPopupContainer={getPopupContainer}
      destroyTooltipOnHide={destroyTooltipOnHide}
    >
      {children}
    </AntdTooltip>
  );
};
