import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";
import { Tooltip } from "../../../../components/_wrapper";
import { DateFormat_MMMDYYYY_WithTimeZone } from "../../../../views/constants";
import "./Demand-History-Item.css";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export interface DemandHistoryItemProps {
  title: string;
  timestamp?: string;
}

export const DemandHistoryItem: React.FC<DemandHistoryItemProps> = ({
  title,
  timestamp,
}) => {
  const timeZone = dayjs.tz.guess();

  return (
    <div className="demand-history-item">
      <div className="demand-history-item-main">
        <p className="text-wrapper-1">{title}</p>
        <Tooltip
          title={
            <p style={{ fontSize: 12 }}>
              {timestamp
                ? dayjs(timestamp)
                    .tz(timeZone)
                    .format(DateFormat_MMMDYYYY_WithTimeZone)
                : ""}
            </p>
          }
          placement="left"
        >
          <p className="text-wrapper-3">
            {timestamp ? dayjs(timestamp).tz(timeZone).fromNow() : ""}
          </p>
        </Tooltip>
      </div>
    </div>
  );
};
