import React, {
  CSSProperties,
  Fragment,
  Suspense,
  useCallback,
  useEffect,
} from "react";

import { message, notification } from "antd";
import { Outlet } from "react-router-dom";
import { ReactComponent as CheckCircleComponent } from "../../assets/check-circle.svg";
import { ReactComponent as CloseComponent } from "../../assets/x.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { resetMessage, resetNotification } from "../../redux/reducer/ui";
import { IMsg, INotify } from "../../views/interfaces";
import { Header } from "../Header";
import { Loader } from "../Loader";
import { Notification } from "../Notification";
import { Sidebar } from "../Sidebar";
import { SVGIcon } from "../SVG-Icon";
import "./Layout.css";
import { NoAccess } from "../No-Access";

export interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = () => {
  const dispatch = useAppDispatch();
  const { sidebarCollapsed, loading, notify, msg } = useAppSelector(
    (state) => state.ui
  );
  const { profile } = useAppSelector((state) => state.employee);

  const customStyle: CSSProperties = {
    cursor: loading > 0 ? "wait" : "default",
    pointerEvents: loading > 0 ? "none" : "auto",
  };

  const [notificationApi, notificationContextHolder] =
    notification.useNotification({
      stack: {
        threshold: 3,
      },
    });
  const [messageApi, messageContextHolder] = message.useMessage({
    maxCount: 3,
  });

  const showNotification = useCallback(
    (notify: INotify) => {
      notificationApi.open({
        ...notify,
        description: Notification(notify.description),
        icon:
          notify.type === "error" ? (
            <SVGIcon
              SVGElementIcon={CloseComponent}
              color="#fe5000"
              cursorPointer={false}
            ></SVGIcon>
          ) : (
            <SVGIcon
              SVGElementIcon={CheckCircleComponent}
              cursorPointer={false}
            ></SVGIcon>
          ),
        closeIcon: <SVGIcon SVGElementIcon={CloseComponent}></SVGIcon>,
        onClose: () => dispatch(resetNotification()),
        pauseOnHover: true,
        showProgress: false,
      });
    },
    [notificationApi, dispatch]
  );

  const showMessage = useCallback(
    (msg: IMsg) => {
      messageApi.open({
        ...msg,
        onClose: () => dispatch(resetMessage()),
      });
    },
    [messageApi, dispatch]
  );

  useEffect(() => {
    if (notify.show) {
      showNotification(notify);
    }
    if (msg.show) {
      showMessage(msg);
    }
  }, [notify, msg, showNotification, showMessage, dispatch]);

  return (
    <Fragment>
      {notificationContextHolder}
      {messageContextHolder}
      <div className="container" style={customStyle}>
        <Sidebar />
        <div
          className={["layout", sidebarCollapsed ? "collapsed" : ""]
            .join(" ")
            .trim()}
        >
          <Header />
          <main className="main">
            {profile?.moduleList?.length == 0 ? (
              <NoAccess />
            ) : (
              <Suspense fallback={<Loader loading={loading} />}>
                <Outlet />
              </Suspense>
            )}
          </main>
        </div>
      </div>
      <Loader loading={loading} />
    </Fragment>
  );
};
