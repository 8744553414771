import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ChevronsLeft } from "../../assets/left.svg";
import logo from "../../assets/logo.jpg";
import { ReactComponent as ChevronsRight } from "../../assets/right.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  setHeaderTitle,
  setSidebarCollapsedMode,
} from "../../redux/reducer/ui";
import { getEmployeeProfile } from "../../redux/slice/employee";
import { IEmployeeProfile } from "../../views/interfaces";
import { SidebarMenu } from "../Sidebar-Menu";
import { SVGIcon } from "../SVG-Icon";
import "./Sidebar.css";

export interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { sidebarCollapsed } = useAppSelector((state) => state.ui);
  const { profile } = useAppSelector((state) => state.employee);
  const [sidebarMenu, setSidebarMenu] = useState<
    IEmployeeProfile["moduleList"]
  >([]);
  const [activeMenuCode, setActiveMenuCode] = useState<string>("");

  useEffect(() => {
    dispatch(getEmployeeProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile && profile.moduleList?.length > 0) {
      setSidebarMenu(profile.moduleList);
    }
  }, [profile]);

  useEffect(() => {
    const activeMenu = sidebarMenu?.find((item) =>
      location.pathname != "/"
        ? location.pathname == item.endpoint
        : item.isActive
    );
    if (activeMenu) {
      if (location.pathname == "/") {
        navigate(activeMenu.endpoint);
      }
      setActiveMenuCode(activeMenu.code);
      dispatch(setHeaderTitle(activeMenu.name));
    }
  }, [sidebarMenu, location.pathname, dispatch, navigate]);

  const changeActiveMenu = (code: string) => {
    const selectedMenu = sidebarMenu.find((item) => item.code == code);
    if (selectedMenu) {
      navigate(selectedMenu.endpoint);
    }
  };

  return (
    <aside className={`sidebar ${sidebarCollapsed ? "collapsed" : ""}`.trim()}>
      <div className="sidebar-header">
        <div className="sidebar-title">
          <span className="logo-container">
            <img src={logo} height={24} width={24} alt="logo" />
          </span>
          {sidebarCollapsed ? "" : <p className="text-wrapper-1">tribe</p>}
        </div>
        <div
          className="icon"
          onClick={() => dispatch(setSidebarCollapsedMode(!sidebarCollapsed))}
        >
          <SVGIcon
            SVGElementIcon={sidebarCollapsed ? ChevronsRight : ChevronsLeft}
            color="#404041"
          ></SVGIcon>
        </div>
      </div>
      <SidebarMenu
        menuList={sidebarMenu}
        changeActive={changeActiveMenu}
        activeMenuCode={activeMenuCode}
      />
    </aside>
  );
};
