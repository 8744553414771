import {
  AuthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NotFound } from "../../assets/not_found.svg";
import { useAppSelector } from "../../hooks/redux-hooks";
import { SVGIcon } from "../SVG-Icon";
import "./Error.css";
import { Loader } from "../Loader";

export const Error: React.FC = () => {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const graphId = useAppSelector((state) => state.graph.id);
  const { loading } = useAppSelector((state) => state.ui);

  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (!isAuthenticated && !account && graphId.length === 0) {
      navigate("/");
    }
  }, [navigate, isAuthenticated, account, graphId]);
  return (
    <AuthenticatedTemplate>
      <div className="error-main-container">
        <div className="notfound-container">
          <SVGIcon
            SVGElementIcon={NotFound}
            width={"100%"}
            height={"80%"}
            style={{ stroke: "transparent", cursor: "default" }}
          />
        </div>
      </div>
      <Loader loading={loading} />
    </AuthenticatedTemplate>
  );
};
