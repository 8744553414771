import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import React, { Fragment, useEffect } from "react";
import { ScrollRestoration } from "react-router-dom";
import { Layout } from "./components";
import { useAppDispatch, useAppSelector } from "./hooks/redux-hooks";
import { Auth } from "./modules/Auth";
import { loginRequest, logoutRequest } from "./modules/Auth/config";
import { setGraph } from "./redux/reducer/graph";
import { setAvatar, setLogout } from "./redux/reducer/ui";
import { callMsGraph, callMsGraphPhoto } from "./utils/graph";
import { clearLocalStorage } from "./utils/local-storage";
import { clearSessionStorage } from "./utils/session-storage";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const graphId = useAppSelector((state) => state.graph.id);
  const { logout } = useAppSelector((state) => state.ui);

  useEffect(() => {
    if (logout) {
      clearSessionStorage();
      clearLocalStorage();
      instance.logoutRedirect(logoutRequest).catch((e: any) => {
        console.error("SignOut Error: ", e);
      });
    }
  }, [logout, instance, dispatch]);

  useEffect(() => {
    if (account && graphId.length == 0) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response: any) => {
          if (response) {
            dispatch(setLogout(false));
            callMsGraph(response.accessToken).then((result: any) => {
              if (result) {
                dispatch(setGraph(result));
              }
            });
            callMsGraphPhoto(response.accessToken).then((_response: any) => {
              if (_response && _response.status === 200) {
                _response.blob().then((data: any) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(data);
                  reader.onload = () =>
                    dispatch(setAvatar(reader.result?.toString() || ""));
                });
              }
            });
          }
        });
    }
  }, [account, graphId, instance, dispatch]);

  return (
    <Fragment>
      <AuthenticatedTemplate>
        <Layout />
        <ScrollRestoration />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Auth />
      </UnauthenticatedTemplate>
    </Fragment>
  );
};

export default App;
