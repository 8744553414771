import React, { Fragment, FunctionComponent } from "react";
import { useAppSelector } from "../../hooks/redux-hooks";
import { Tooltip } from "../_wrapper";
import { SVGIcon } from "../SVG-Icon";
import "./Sidebar-Menu-Item.css";

export interface SidebarMenuItemProps {
  code: string;
  title: string;
  activeMenuCode: string;
  SVGIconElement: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  changeActive?: (code: string) => void;
}

export const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  code,
  title,
  activeMenuCode,
  SVGIconElement,
  changeActive,
}: SidebarMenuItemProps) => {
  const sidebarCollapsed = useAppSelector((state) => state.ui.sidebarCollapsed);

  const onClickOnSidebarMenuItem = () => {
    if (changeActive) {
      changeActive(code);
    }
  };

  return (
    <div
      className={`sidebar-menu-item ${
        code == activeMenuCode ? "sidebar-menu-item-active" : ""
      } ${!sidebarCollapsed ? "sidebar-menu-item-max-width" : ""}`.trim()}
      onClick={() => onClickOnSidebarMenuItem()}
    >
      <div
        className={`frame ${sidebarCollapsed ? "frame-only-icon" : ""}`.trim()}
      >
        {sidebarCollapsed ? (
          <Tooltip title={title} placement="right">
            <span>
              <SVGIcon
                SVGElementIcon={SVGIconElement}
                active={code == activeMenuCode}
                color="#929497"
                colorActive="#fe5000"
              />
            </span>
          </Tooltip>
        ) : (
          <Fragment>
            <SVGIcon
              SVGElementIcon={SVGIconElement}
              active={code == activeMenuCode}
              color="#929497"
              colorActive="#fe5000"
            />
            <div className="heading">
              <span className="text-wrapper-1">{title}</span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
