import React, { useState } from "react";

import dayjs from "dayjs";
import {
  AntdTableProps,
  Table,
  TableParams,
  defaultPagination,
} from "../../../../components/_wrapper/Table";
import { Tag } from "../../../../components/_wrapper/Tag";
import { Tooltip } from "../../../../components/_wrapper/Tooltip";
import { DateFormat_DDMMMYYYY } from "../../../../views/constants";
import { IExternalFulfillmentTable } from "../../../../views/interfaces";
import "./External-Fulfillment.css";

export interface ExternalFulfillmentProps {
  data: IExternalFulfillmentTable[];
}

export const ExternalFulfillment: React.FC<ExternalFulfillmentProps> = ({
  data,
}) => {
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: defaultPagination,
  });

  const tableColumns: AntdTableProps<IExternalFulfillmentTable>["columns"] = [
    {
      title: "Candidate Name",
      dataIndex: "candidateName",
      key: "candidateName",
      sorter: false,
      render: (candidateName) => (
        <Tooltip
          title={candidateName}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <span>{candidateName}</span>
        </Tooltip>
      ),
    },
    {
      title: "Candidate Status",
      dataIndex: "stage",
      key: "stage",
      sorter: false,
      render: (candidateStatus) => (
        <Tag
          color="#ECFDF3"
          textColor="#027A48"
          style={{ padding: "2px 8px 2px 6px" }}
        >
          <span>{candidateStatus}</span>
        </Tag>
      ),
    },
    {
      title: "TA SPOC",
      dataIndex: "recruiterEmailId",
      key: "recruiterEmailId",
      sorter: false,
      render: (taSpoc) => (
        <Tooltip title={taSpoc} placement="bottomRight">
          <span style={{ fontWeight: 600 }}>{taSpoc ? taSpoc : "-"}</span>
        </Tooltip>
      ),
    },
    {
      title: "Expected DOJ",
      dataIndex: "expectedDoj",
      key: "expectedDoj",
      sorter: false,
      render: (expectedDoj) => {
        return (
          <span>
            {expectedDoj
              ? dayjs(expectedDoj).format(DateFormat_DDMMMYYYY)
              : "-"}
          </span>
        );
      },
    },
  ];

  return (
    <div className="external-fulfillment-table">
      <Table
        columns={tableColumns}
        dataSource={data}
        tableParams={tableParams}
        setTableParams={setTableParams}
        totalCount={data.length}
        showPagination={false}
      />
    </div>
  );
};
