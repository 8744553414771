import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import { Sidebar } from "../components";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

const Error = React.lazy(() =>
  import("../components/Error").then((module) => ({
    default: module.Error,
  }))
);

const Admin = React.lazy(() =>
  import("../modules/Admin").then((module) => ({
    default: module.Admin,
  }))
);

const Logs = React.lazy(() =>
  import("../modules/Admin/components").then((module) => ({
    default: module.Logs,
  }))
);

const AllEmployees = React.lazy(() =>
  import("../modules/All-Employees").then((module) => ({
    default: module.AllEmployees,
  }))
);

const Dashboard = React.lazy(() =>
  import("../modules/Dashboard").then((module) => ({
    default: module.Dashboard,
  }))
);

const DraftDemands = React.lazy(() =>
  import("../modules/Draft-Demands").then((module) => ({
    default: module.DraftDemands,
  }))
);

const OpenDemands = React.lazy(() =>
  import("../modules/Open-Demands").then((module) => ({
    default: module.OpenDemands,
  }))
);

const Reports = React.lazy(() =>
  import("../modules/Reports").then((module) => ({
    default: module.Reports,
  }))
);

export const router = createBrowserRouter([
  {
    path: "/",
    caseSensitive: true,
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Dashboard />,
        errorElement: <Error />,
      },
      {
        path: "dashboard",
        caseSensitive: true,
        element: <Dashboard />,
        errorElement: <Error />,
      },
      // {
      //   path: "draft-demand",
      //   caseSensitive: true,
      //   element: <DraftDemands />,
      //   errorElement: <Error />,
      // },
      {
        path: "open-demand",
        caseSensitive: true,
        element: <OpenDemands />,
        errorElement: <Error />,
      },
      // {
      //   path: "employee",
      //   caseSensitive: true,
      //   element: <AllEmployees />,
      //   errorElement: <Error />,
      // },
      {
        path: "report",
        caseSensitive: true,
        element: <Reports />,
        errorElement: <Error />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <>
        <AuthenticatedTemplate>
          <Sidebar />
          <Error />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Error />
        </UnauthenticatedTemplate>
      </>
    ),
  },
]);
