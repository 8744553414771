import React, { Fragment, useEffect, useState } from "react";

import { sortCheckUncheckedFilter } from "../../utils";
import { IFilterOption, TFilterDisplay } from "../../views/interfaces";
import { Drawer } from "../_wrapper/Drawer";
import { DrawerHeader } from "../_wrapper/Drawer-Header";
import { Empty } from "../_wrapper/Empty";
import { FilterOption } from "../Filter-Option";
import "./Filter-Advanced-Item.css";

export interface FilterAdvancedItemProps {
  label: string;
  data: IFilterOption[];
  onChange?: (
    label: any,
    value: string,
    checked: boolean,
    filterLabel?: string
  ) => void;
  hideCount?: boolean;
  filterType?: "group" | "item";
  open?: boolean;
}

export const FilterAdvancedItem: React.FC<FilterAdvancedItemProps> = ({
  label,
  data,
  onChange = () => {},
  hideCount = false,
  filterType = "item",
}) => {
  const [openFullViewFilter, setOpenFullViewFilter] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<IFilterOption[]>([]);
  const [enableSearch, setEnableSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [displayType, setDisplayType] =
    useState<TFilterDisplay>("advanced-short");

  useEffect(() => {
    let filteredData: IFilterOption[] = [];
    if (searchString && searchString.length > 0) {
      filteredData = data.map((item) => {
        return {
          ...item,
          children:
            item?.children?.filter((child) =>
              child.label.toLowerCase().includes(searchString.toLowerCase())
            ) ?? [],
        };
      });
      filteredData = filteredData.filter((dt) => {
        return dt.children && dt.children.length > 0
          ? dt
          : dt.label.toLowerCase().includes(searchString.toLowerCase());
      });
    } else {
      filteredData = sortCheckUncheckedFilter(data, true);
    }
    setFilterData(filteredData);
  }, [searchString, data, filterType]);

  const openFullViewFilterHandler = () => {
    setSearchString(null);
    setEnableSearch(false);
    setDisplayType("advanced");
    setOpenFullViewFilter(true);
  };

  const closeFullViewFilterHandler = () => {
    setSearchString(null);
    setEnableSearch(false);
    setDisplayType("advanced-short");
    setOpenFullViewFilter(false);
  };

  return (
    <Fragment>
      <div className="filter-advanced-item">
        <DrawerHeader
          headerLabel={label}
          showSearch={true}
          enableSearch={enableSearch}
          setEnableSearch={(state) => {
            setEnableSearch(state);
            setSearchString(null);
          }}
          setSearchString={setSearchString}
        />
        {filterData && filterData.length > 0 ? (
          <FilterOption
            data={filterData}
            action={onChange}
            displayType={displayType}
            openFullViewFilter={openFullViewFilterHandler}
            filterType={filterType}
            setDisplayType={(type) => {
              setDisplayType(type);
            }}
          />
        ) : (
          <Empty />
        )}
      </div>

      {/* Full Screen Advanced filter for Single filter */}
      <Drawer
        title={label}
        open={openFullViewFilter}
        onClose={closeFullViewFilterHandler}
        width={560}
        showSearchHeader={true}
        searchHeaderState={enableSearch}
        searchHeaderAction={setEnableSearch}
        showCloseButtonOnLeftHeader={true}
        setSearchString={setSearchString}
        children={
          <div className="filter-advanced filter-advanced-full-view">
            <div className="filter-advanced-full-view-option">
              <FilterOption
                data={filterData}
                action={onChange}
                displayType={displayType}
              />
            </div>
          </div>
        }
      />
    </Fragment>
  );
};
