import axios from "axios";
import { BACKEND_URL } from "../environment";
import { fetchToken } from "../utils/fetch-token";
import { ReduxApiService } from "../views/enums";

export const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const token = await fetchToken();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token.idtoken}`,
      },
    };
  },
  (error) => {
    throw error;
  }
);

export const ApiRoutesConfig = {
  [ReduxApiService.getFilterData]: "/filter/dashboard",
  [ReduxApiService.getDraftFilterData]: "/filter/draft-demand",
  [ReduxApiService.getOpenFilterData]: "/filter/open-demand",
  [ReduxApiService.getEmployeeFilterData]: "/filter/employee",
  [ReduxApiService.getCardData]: "/dashboard/data",
  [ReduxApiService.getDraftTableData]: "/demand/draft/data",
  [ReduxApiService.exportDraftTableData]: "/demand/draft/report",
  [ReduxApiService.getOpenTableData]: "/demand/open/data",
  [ReduxApiService.exportOpenTableData]: "/demand/open/report",
  [ReduxApiService.getEmployeeTableData]: "/employee/data",
  [ReduxApiService.exportEmployeeTableData]: "/employee/report",
  [ReduxApiService.bulkFulfillmentUpdate]:
    "/demands-creation/bulk-fulfillment-update",
  [ReduxApiService.getDemandCreationData]: "/demands-creation/form-input-data",
  [ReduxApiService.saveSubmitDemandCreationForm]:
    "/demands-creation/save-submit-form",
  [ReduxApiService.getDemandHistory]: "/demands-creation/demand-history",
  [ReduxApiService.cancelDemandForm]: "/demands-creation/cancel-form",
  [ReduxApiService.convertDemandForm]: "/demands-creation/convert-form",
  [ReduxApiService.getEmployeeForProposal]: "/demands-creation/fetch-employee",
  [ReduxApiService.proposeSelectedEmployee]: "/demands-creation/propose",
  [ReduxApiService.getActiveProposals]: "/demands-creation/active-proposals",
  [ReduxApiService.getRejectionReasons]: "/demands-creation/rejection-reasons",
  [ReduxApiService.getCancellationReasons]:
    "/demands-creation/cancellation-reasons",
  [ReduxApiService.updateActiveProposalStatus]:
    "/demands-creation/update-proposal",
  [ReduxApiService.getProposalHistory]: "/demands-creation/proposal-history",
  [ReduxApiService.getExternalFulfillment]:
    "/demands-creation/external-fulfillment",
  [ReduxApiService.getUiFilterItems]: "/filter/ui-components",
  [ReduxApiService.demandApproveOrReject]:
    "/demands-creation/bulk-demand-approve",
  [ReduxApiService.openDemandReport]: "/reports/open-demand",
  [ReduxApiService.createJdUsingAI]: "/jd/create",
  [ReduxApiService.getRmgReasons]: "/demands-creation/rmg-reasons",
  [ReduxApiService.closeByRmg]: "/demands-creation/close-demand",
  [ReduxApiService.getEmployeeProfile]: "/employee/profile",
  [ReduxApiService.sendEmail]: "/email/send",
  [ReduxApiService.onHoldOrRelease]: "/demands-creation/hold-release",
};
