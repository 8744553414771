import { setMessage } from "../redux/reducer/ui";

export const generateToastMessageContentService = (error: any) => {
  if (error?.response?.data?.message) {
    return `${error.response.statusText}: ${error.response.data.message}`;
  } else if (error?.message) {
    return error.message;
  } else {
    return "Error Occurred!";
  }
};

export const triggerMessageService = (
  store: any,
  action: any,
  content?: string
) => {
  store.dispatch(
    setMessage({
      show: true,
      key: action.type,
      type: "error",
      content: content ?? generateToastMessageContentService(action),
    })
  );
};
