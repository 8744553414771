import React, { Fragment, useCallback, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { ReactComponent as EntityIcon } from "../../assets/apartment.svg";
import { Tag } from "../../components/_wrapper";
import { AntdTabsProps, Tabs } from "../../components/_wrapper/Tabs";
import { SVGIcon } from "../../components/SVG-Icon";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { resetCloseDrawer, resetFormSubmitted } from "../../redux/slice/demand";
import { setReloadDraftDemand } from "../../redux/slice/draft-demand";
import { setReloadOpenDemand } from "../../redux/slice/open-demand";
import { IDemandMeta, TDemandForm } from "../../views/interfaces";
import { DemandForm } from "./components/Demand-Form";
import { DemandFulfillment } from "./components/Demand-Fulfillment";
import { DemandHistory } from "./components/Demand-History";
import "./Demand.css";

export interface DemandProps {
  closeForm: () => void;
  metaData?: IDemandMeta;
  activeKey?: string;
  type?: TDemandForm;
  isRmAdmin?: boolean;
}

export const Demand: React.FC<DemandProps> = ({
  closeForm,
  metaData = {
    defreqInternalId: 0,
    demandId: "",
    defreqId: "",
    vertical: { id: 0, name: "Vertical" },
    subVertical: { id: 0, name: "Sub Vertical" },
    practice: { id: 0, name: "Practice" },
    level: "L1",
    role: { id: 0, name: "Role" },
    account: { id: 0, name: "Account" },
    project: { id: 0, name: "Project" },
    opportunity: { id: 0, name: "Opportunity" },
    entity: { id: 0, name: "Entity" },
    demandStatus: {
      status: "",
      subStatus: "",
      fulfillmentType: "",
    },
    inHouseDemand: false,
  },
  activeKey = "1",
  type = "new",
  isRmAdmin = false,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { closeDemandDrawer, formSubmitted } = useAppSelector(
    (state) => state.demand
  );
  const [activeTab, setActiveTab] = useState<string>(activeKey);

  useEffect(() => {
    if (closeDemandDrawer) {
      if (location.pathname == "/open-demand") {
        dispatch(setReloadOpenDemand(formSubmitted));
      }
      if (location.pathname == "/draft-demand") {
        dispatch(setReloadDraftDemand(formSubmitted));
      }
      dispatch(resetCloseDrawer());
      dispatch(resetFormSubmitted());
      closeForm();
    }
  }, [
    closeDemandDrawer,
    formSubmitted,
    dispatch,
    location.pathname,
    closeForm,
  ]);

  const tabsItems = useCallback(() => {
    const tabs = [
      {
        key: "1",
        label: "Demand",
        children: <DemandForm formType={type} meta={metaData} />,
      },
      {
        key: "2",
        label: "Demand History",
        children: <DemandHistory meta={metaData} />,
      },
    ];
    if (type == "open") {
      tabs.splice(1, 0, {
        key: "3",
        label: "Fulfillment",
        children: <DemandFulfillment meta={metaData} />,
      });
    }
    return tabs as AntdTabsProps["items"];
  }, [type, metaData]);

  return (
    <div className="demand">
      {type == "new" || (type == "draft" && metaData?.inHouseDemand) ? (
        <DemandForm formType={type} meta={metaData} />
      ) : (
        <Fragment>
          <div className="demand-sub-header">
            <div className="demand-section1">
              <Tag
                color="#FEEFE2"
                textColor="#D6680B"
                style={{ fontVariant: "small-caps" }}
              >
                {metaData.subVertical.name
                  ? metaData.subVertical.name.toLowerCase()
                  : metaData.vertical.name?.toLowerCase()}
              </Tag>
              <Tag
                color="#d4deff"
                textColor="#363f72"
                style={{ fontVariant: "small-caps" }}
              >
                {metaData.practice.name?.toLowerCase()}
              </Tag>
              <Tag
                color="#FDF2FA"
                textColor="#C11574"
                style={{ fontVariant: "small-caps" }}
              >
                {metaData.level.toLowerCase()}
              </Tag>
            </div>
            <div className="demand-section2">
              <p className="job-title">Job Title</p>
              <p className="role-position">
                {metaData?.defreqId}
                {" | "}
                <span className="text-wrapper-2">{metaData.role.name}</span>
              </p>
              {metaData.entity.name && (
                <p className="location">
                  <SVGIcon SVGElementIcon={EntityIcon} height={16} width={16} />
                  <span className="text-wrapper-3">
                    {metaData.entity.name?.toLowerCase()}
                  </span>
                </p>
              )}
            </div>
            <div className="demand-section3">
              <div className="demand-section3-frame">
                <p className="demand-section3-frame-title">Account Name</p>
                <p className="demand-section3-frame-content">
                  {metaData.account.name}
                </p>
              </div>
              <div className="demand-section3-frame">
                <p className="demand-section3-frame-title">
                  {metaData.project.name ? "Project Name" : "Opportunity Name"}
                </p>
                <p className="demand-section3-frame-content">
                  {metaData.project.name
                    ? metaData.project.name
                    : metaData.opportunity.name}
                </p>
              </div>
              <div className="demand-section3-frame">
                <p className="demand-section3-frame-title">Demand Status</p>
                <p className="demand-section3-frame-content">
                  {metaData.demandStatus.status == "open"
                    ? metaData.demandStatus.subStatus
                    : metaData.demandStatus.status}
                </p>
              </div>
            </div>
          </div>
          <div className="demand-content">
            <Tabs
              items={tabsItems()}
              activeTab={activeTab}
              setActiveTab={(key) => setActiveTab(key)}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};
