import React, { Fragment, useCallback, useEffect, useState } from "react";

import { ReactComponent as TableActionIcon } from "../../assets/more-vertical.svg";
import { Demand, SubHeader, SVGIcon } from "../../components";
import { Drawer, Tooltip } from "../../components/_wrapper";
import { AntdMenuProps, Dropdown } from "../../components/_wrapper/Dropdown";
import {
  AntdTableProps,
  defaultPagination,
  Table,
  TableParams,
} from "../../components/_wrapper/Table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  defaultFilterData,
  exportDraftTableData,
  getDraftFilterData,
  getDraftTableData,
  setReloadDraftDemand,
} from "../../redux/slice/draft-demand";
import {
  getSelectedFilter,
  limitOffset,
  sort,
  updateFilterObject,
} from "../../utils";
import { Filters, SortByColumn } from "../../views/enums";
import {
  IDemandMeta,
  IDraftDemandFilter,
  IDraftDemandSelectedFilter,
  IDraftDemandTable,
  ISort,
} from "../../views/interfaces";
import "./Draft-Demands.css";

export const defaultSelectedFilterData: IDraftDemandSelectedFilter = {
  account: [],
  vertical: [],
  subVertical: [],
  practice: [],
  project: [],
  opportunity: [],
  location: [],
  legalEntity: [],
};

export interface DraftDemandsProps {}

export const DraftDemands: React.FC<DraftDemandsProps> = () => {
  const dispatch = useAppDispatch();
  const { filterData, tableData, totalCount, sortData, reloadDraftDemand } =
    useAppSelector((state) => state.draftDemands);

  const [openDemandForm, setOpenDemandForm] = useState<{
    state: boolean;
    key?: string;
    meta?: IDemandMeta;
  }>({ state: false });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: defaultPagination as TableParams["pagination"],
  });
  const [filter, setFilter] = useState<IDraftDemandFilter>(defaultFilterData);
  const [selectedFilters, setSelectedFilter] =
    useState<IDraftDemandSelectedFilter>(defaultSelectedFilterData);
  const [isSelectedFilterChanged, setIsSelectedFilterChanged] =
    useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<ISort | null>(null);

  const toggleDemandCreationForm = useCallback(
    (value: boolean, key?: string, demandId?: string, defreqId?: string) => {
      if (value) {
        const selectedDemand = tableData.find(
          (item) =>
            item.id.demandId === demandId || item.id.defreqId === defreqId
        );
        let metaData: IDemandMeta | undefined;
        if (selectedDemand) {
          metaData = {
            defreqInternalId: 0,
            demandId: selectedDemand.id.demandId,
            defreqId: selectedDemand.id.defreqId,
            vertical: selectedDemand.practiceVerticalSubVertical.vertical,
            subVertical: selectedDemand.practiceVerticalSubVertical.subVertical,
            practice: selectedDemand.practiceVerticalSubVertical.practice,
            level: selectedDemand.levelRole.level,
            role: selectedDemand.levelRole.role,
            account: selectedDemand.accountProjectOpportunity.account,
            project: selectedDemand.accountProjectOpportunity.project,
            opportunity: selectedDemand.accountProjectOpportunity.opportunity,
            entity: selectedDemand.entity,
            demandStatus: {
              status: "Not Acted",
              fulfillmentType: "Not Acted",
              subStatus: "",
            },
            inHouseDemand: selectedDemand.inHouseDemand,
          };
        }
        setOpenDemandForm({ state: value, key: key, meta: metaData });
      } else {
        setOpenDemandForm({ state: value });
      }
    },
    [tableData]
  );

  const getTableData = useCallback(() => {
    dispatch(
      getDraftTableData({
        body: {
          sort: sort(tableParams.sortOrder, sortBy?.columnName),
          filter: selectedFilters,
          searchQuery: searchString ?? "",
        },
        params: {
          ...limitOffset(tableParams),
        },
      })
    );
  }, [
    dispatch,
    searchString,
    selectedFilters,
    sortBy?.columnName,
    tableParams,
  ]);

  useEffect(() => {
    if (JSON.stringify(filterData) === JSON.stringify(defaultFilterData))
      return;
    setFilter({ ...filterData });
  }, [filterData]);

  useEffect(() => {
    dispatch(getDraftFilterData());
  }, [dispatch]);

  useEffect(() => {
    if (isSelectedFilterChanged) {
      const projects = filter.projectOpportunity.filter(
        (item: any) => item.id == 1
      );
      const opportunities = filter.projectOpportunity.filter(
        (item: any) => item.id == 2
      );
      const subVertical = filter.vertical
        .map((item) => item?.children ?? [])
        .flat();
      setSelectedFilter({
        account: getSelectedFilter(filter.account),
        practice: getSelectedFilter(filter.practice),
        project: getSelectedFilter(projects),
        opportunity: getSelectedFilter(opportunities),
        vertical: getSelectedFilter(filter.vertical),
        subVertical: getSelectedFilter(subVertical),
        location: getSelectedFilter(filter.location),
        legalEntity: getSelectedFilter(filter.legalEntity),
      });
      setIsSelectedFilterChanged(false);
    }
  }, [isSelectedFilterChanged, filter, dispatch]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  useEffect(() => {
    if (reloadDraftDemand) {
      getTableData();
      dispatch(setReloadDraftDemand(false));
    }
  }, [reloadDraftDemand, getTableData, dispatch]);

  const onChangeFilter = (
    key: keyof IDraftDemandFilter,
    label: any,
    value: string,
    checked: boolean
  ) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: prev[key].map((item) => {
          if (item.value === value && item.label === label) {
            return {
              ...item,
              tempChecked: checked,
              isUpdated: true,
              children: item.children?.map((child) => {
                return {
                  ...child,
                  tempChecked: checked,
                  isUpdated: true,
                };
              }),
            };
          } else if (item.children && item.children.length > 0) {
            let isChildUpdated = false;
            const selectedChild = item.children.map((child) => {
              if (child.value === value && child.label === label) {
                isChildUpdated = true;
                return {
                  ...child,
                  tempChecked: checked,
                  isUpdated: true,
                };
              }
              return child;
            });
            if (!isChildUpdated) {
              return item;
            }
            const isChildTempChecked = selectedChild.every((child) =>
              child.isUpdated ? child.tempChecked : child.checked
            );
            const isChildIndeterminate = selectedChild.some((child) =>
              child.isUpdated ? child.tempChecked : child.checked
            );
            return {
              ...item,
              isUpdated: isChildUpdated,
              tempChecked: isChildTempChecked,
              indeterminate: !isChildTempChecked && isChildIndeterminate,
              children: selectedChild,
            };
          }
          return item;
        }),
      };
    });
  };

  const filterApply = (
    apply: boolean = false,
    clear: boolean = false,
    key?: keyof IDraftDemandFilter
  ) => {
    if (key) {
      setFilter((prev) => {
        return {
          ...prev,
          [key]: updateFilterObject(apply, prev[key]),
        };
      });
    } else {
      setFilter((prev) => {
        return {
          account: updateFilterObject(apply, prev.account),
          projectOpportunity: updateFilterObject(
            apply,
            prev.projectOpportunity
          ),
          vertical: updateFilterObject(apply, prev.vertical),
          practice: updateFilterObject(apply, prev.practice),
          location: updateFilterObject(apply, prev.location),
          legalEntity: updateFilterObject(apply, prev.legalEntity),
        };
      });
    }
    if (apply) {
      setClearFilters(true);
      setIsSelectedFilterChanged(true);
    } else if (clear) {
      setFilter({ ...filterData });
      setClearFilters(false);
      setIsSelectedFilterChanged(true);
    } else {
      setIsSelectedFilterChanged(false);
    }
  };

  const dropdownItems = useCallback(
    (demandId: string, defreqId: string) => {
      return [
        {
          key: "1",
          label: (
            <span
              onClick={() =>
                toggleDemandCreationForm(true, "1", demandId, defreqId)
              }
            >
              Demand Details
            </span>
          ),
        },
      ] as AntdMenuProps["items"];
    },
    [toggleDemandCreationForm]
  );

  const exportTableData = () => {
    dispatch(
      exportDraftTableData({
        body: {
          sort: sort(tableParams.sortOrder, sortBy?.columnName),
          filter: selectedFilters,
          searchQuery: searchString ?? "",
        },
      })
    );
  };

  const tableColumns: AntdTableProps<IDraftDemandTable>["columns"] = [
    {
      title: "Demand ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: ({ demandId, defreqId }) => (
        <>
          <span>{defreqId}</span>
        </>
      ),
    },
    {
      title: <span>Demand Type</span>,
      dataIndex: "demandType",
      key: "demandType",
      width: 72,
      render: (demandType) => (
        <>
          <Tooltip title={demandType} placement="bottomRight">
            <span>{demandType}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Level
          <br />
          Role
        </span>
      ),
      dataIndex: "levelRole",
      key: "levelRole",
      width: 80,
      sorter: SortByColumn.level == sortBy?.columnName,
      render: ({ level, role }) => (
        <>
          <span>{level}</span>
          <br />
          <Tooltip title={role?.name} placement="bottomRight">
            <span>{role?.name}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Practice
          <br />
          Sub-Vertical
        </span>
      ),
      dataIndex: "practiceVerticalSubVertical",
      key: "practiceVerticalSubVertical",
      sorter: SortByColumn.practice == sortBy?.columnName,
      render: ({ practice, vertical, subVertical }) => (
        <>
          <Tooltip title={practice?.name} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{practice?.name}</span>
          </Tooltip>
          <br />
          <Tooltip
            title={
              subVertical.name.length > 0 ? subVertical.name : vertical.name
            }
            placement="bottomRight"
          >
            <span>
              {subVertical.name.length > 0 ? subVertical.name : vertical.name}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Account
          <br />
          Project / Opp.
        </span>
      ),
      dataIndex: "accountProjectOpportunity",
      key: "accountProjectOpportunity",
      render: ({ account, opportunity, project }) => (
        <>
          <Tooltip title={account?.name} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{account?.name}</span>
          </Tooltip>
          <br />
          <Tooltip
            title={
              project?.name && project?.name.length > 0
                ? project?.name
                : opportunity?.name
            }
            placement="bottomRight"
          >
            <span>
              {project?.name && project?.name.length > 0
                ? project?.name
                : opportunity?.name}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Pos. Start &<br />
          End Date
        </span>
      ),
      dataIndex: "position",
      key: "position",
      width: 72,
      sorter: SortByColumn.startDate == sortBy?.columnName,
      render: ({ startDate, endDate }) => (
        <>
          <span>{startDate}</span>
          <br />
          <span>{endDate}</span>
        </>
      ),
    },
    {
      title: (
        <span>
          Allocation
          <br />
          Percentage
        </span>
      ),
      dataIndex: "allocationPercentage",
      key: "allocationPercentage",
      width: 64,
      render: (allocationPercentage) => <>{allocationPercentage}</>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: false,
      width: 40,
      render: (_, record) => {
        return (
          <Dropdown
            placement="bottomLeft"
            menu={{
              items: dropdownItems(record.id.demandId, record.id.defreqId),
            }}
            destroyPopupOnHide={true}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SVGIcon SVGElementIcon={TableActionIcon} color="#59595A" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="draft-demands">
        <SubHeader
          filters={[
            {
              label: Filters.account,
              is_searchable: true,
              data: filter?.account,
              onChange: (label, value, checked) => {
                onChangeFilter("account", label, value, checked);
              },
              action: (apply, clear) => filterApply(apply, clear, "account"),
            },
            {
              label: Filters.projectOpportunity,
              is_searchable: true,
              data: filter?.projectOpportunity,
              onChange: (label, value, checked) => {
                onChangeFilter("projectOpportunity", label, value, checked);
              },
              action: (apply, clear) =>
                filterApply(apply, clear, "projectOpportunity"),
            },
          ]}
          advancedFilter={{
            action: (apply, clear) => filterApply(apply, clear),
            clearFilters: clearFilters,
            data: [
              {
                label: Filters.account,
                data: filter?.account,
                onChange: (label, value, checked) => {
                  onChangeFilter("account", label, value, checked);
                },
              },
              {
                label: Filters.projectOpportunity,
                data: filter?.projectOpportunity,
                onChange: (label, value, checked) => {
                  onChangeFilter("projectOpportunity", label, value, checked);
                },
                action: (apply, clear) =>
                  filterApply(apply, clear, "projectOpportunity"),
              },
              {
                label: Filters.vertical,
                data: filter?.vertical.map((item) => {
                  return {
                    ...item,
                    checked: item.tempChecked || item.checked,
                    filterType:
                      item.children && item.children.length > 0
                        ? "group"
                        : "item",
                  };
                }),
                onChange: (label, value, checked) => {
                  onChangeFilter("vertical", label, value, checked);
                },
                filterType: "group",
              },
              {
                label: Filters.practice,
                data: filter?.practice,
                onChange: (label, value, checked) => {
                  onChangeFilter("practice", label, value, checked);
                },
              },
              {
                label: Filters.location,
                data: filter?.location,
                onChange: (label, value, checked) => {
                  onChangeFilter("location", label, value, checked);
                },
              },
              {
                label: Filters.legalEntity,
                data: filter?.legalEntity,
                onChange: (label, value, checked) => {
                  onChangeFilter("legalEntity", label, value, checked);
                },
              },
            ],
          }}
          setSearchString={setSearchString}
          onClickExportData={exportTableData}
          showSearchBar={true}
          showExportDataButton={true}
          sortOption={{
            data: sortData,
            action: (sortBy) => {
              setSortBy(sortBy);
              setTableParams((prev) => {
                return {
                  ...prev,
                  sortOrder: "ascend",
                };
              });
            },
            selected: sortBy,
          }}
        />
        <div className="draft-demands-table">
          <Table
            columns={tableColumns}
            dataSource={tableData}
            tableParams={tableParams}
            setTableParams={setTableParams}
            totalCount={totalCount}
          />
        </div>
      </div>
      <Drawer
        title="Draft Demand Form"
        open={openDemandForm.state}
        onClose={() => toggleDemandCreationForm(false)}
        width={840}
        children={
          <Demand
            closeForm={() => toggleDemandCreationForm(false)}
            type="draft"
            activeKey={openDemandForm.key}
            metaData={openDemandForm.meta}
          />
        }
      />
    </Fragment>
  );
};
