import React, { useCallback, useEffect, useState } from "react";
import { DashboardCard, SubHeader } from "../../components";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  defaultFilterData,
  getCardData,
  getFilterData,
  getUiFilterItems,
} from "../../redux/slice/dashboard";
import { getSelectedFilter, updateFilterObject } from "../../utils";
import { EModuleCode, EPermissionType } from "../../views/enums";
import {
  IDashboardFilter,
  IDashboardSelectedFilter,
} from "../../views/interfaces";
import "./Dashboard.css";
import { DashboardFilterCodeList } from "../../views/constants";

const defaultSelectedFilterData: IDashboardSelectedFilter = {
  vertical: [],
  practice: [],
  account: [],
  subVertical: [],
};

export interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = () => {
  const dispatch = useAppDispatch();
  const { filterData, cardData, filterConfig } = useAppSelector(
    (state) => state.dashboard
  );
  const [moduleId, setModuleId] = useState<number | undefined>(undefined);
  const { profile } = useAppSelector((state) => state.employee);
  const [filter, setFilter] = useState<IDashboardFilter>(defaultFilterData);
  const [isSelectedFilterChanged, setIsSelectedFilterChanged] =
    useState<boolean>(false);
  const [selectedFilters, setSelectedFilter] =
    useState<IDashboardSelectedFilter>(defaultSelectedFilterData);

  useEffect(() => {
    if (profile) {
      setModuleId(
        profile?.moduleList?.find(
          (module) => module.code == EModuleCode.M_DASHBOARD
        )?.id
      );
    }
  }, [profile]);

  const getDashboardData = useCallback(
    (moduleId: number) => {
      dispatch(
        getCardData({
          body: {
            filter: selectedFilters,
          },
          params: {
            mId: moduleId,
          },
        })
      );
    },
    [dispatch, selectedFilters]
  );

  useEffect(() => {
    if (moduleId) {
      getDashboardData(moduleId);
    }
  }, [moduleId, getDashboardData]);

  useEffect(() => {
    if (JSON.stringify(filterData) === JSON.stringify(defaultFilterData))
      return;
    setFilter(filterData);
  }, [filterData]);

  useEffect(() => {
    if (moduleId) {
      dispatch(getUiFilterItems({ mId: moduleId }));
      dispatch(getFilterData());
    }
  }, [dispatch, moduleId]);

  useEffect(() => {
    if (isSelectedFilterChanged) {
      const subVertical = filter.vertical
        .map((item) => item?.children ?? [])
        .flat();
      setSelectedFilter({
        practice: getSelectedFilter(filter.practice),
        account: getSelectedFilter(filter.account),
        vertical: getSelectedFilter(filter.vertical),
        subVertical: getSelectedFilter(subVertical),
      });
      setIsSelectedFilterChanged(false);
    }
  }, [isSelectedFilterChanged, dispatch, filter]);

  const onChangeFilter = (
    key: keyof IDashboardFilter,
    label: any,
    value: string,
    checked: boolean
  ) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: prev[key].map((item) => {
          if (item.value === value && item.label === label) {
            return {
              ...item,
              tempChecked: checked,
              isUpdated: true,
              children: item.children?.map((child) => {
                return {
                  ...child,
                  tempChecked: checked,
                  isUpdated: true,
                };
              }),
            };
          } else if (item.children && item.children.length > 0) {
            let isChildUpdated = false;
            const selectedChild = item.children.map((child) => {
              if (child.value === value && child.label === label) {
                isChildUpdated = true;
                return {
                  ...child,
                  tempChecked: checked,
                  isUpdated: true,
                };
              }
              return child;
            });
            if (!isChildUpdated) {
              return item;
            }
            const isChildTempChecked = selectedChild.every((child) =>
              child.isUpdated ? child.tempChecked : child.checked
            );
            const isChildIndeterminate = selectedChild.some((child) =>
              child.isUpdated ? child.tempChecked : child.checked
            );
            return {
              ...item,
              isUpdated: isChildUpdated,
              tempChecked: isChildTempChecked,
              indeterminate: !isChildTempChecked && isChildIndeterminate,
              children: selectedChild,
            };
          }
          return item;
        }),
      };
    });
  };

  const filterApply = (key: keyof IDashboardFilter, apply: boolean = false) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: updateFilterObject(apply, prev[key]),
      };
    });
    if (apply) {
      setIsSelectedFilterChanged(true);
    } else {
      setIsSelectedFilterChanged(false);
    }
  };

  return (
    <div className="dashboard">
      <SubHeader
        dashboardScreen={true}
        filters={filterConfig.filter(filter=>DashboardFilterCodeList.indexOf(filter.code)!==-1).map((item) => {
          return {
            label: item.label,
            data: filter[item.type as keyof IDashboardFilter],
            is_searchable: item.is_searchable,
            onChange: (label, value, checked) => {
              onChangeFilter(
                item.type as keyof IDashboardFilter,
                label,
                value,
                checked
              );
            },
            action: (apply) =>
              filterApply(item.type as keyof IDashboardFilter, apply),
            filterType: item.type == "vertical" ? "group" : "item",
          };
        })}
      />
      <div className="cards">
        {cardData
          .filter(
            (card) =>
              card.top.permission === EPermissionType.Write ||
              card.top.permission === EPermissionType.Read
          )
          .map((data, idx) => (
            <DashboardCard
              data={data}
              key={idx}
              SubHeaderFilters={selectedFilters}
            />
          ))}
      </div>
    </div>
  );
};
