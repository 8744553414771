import { createAction, createReducer } from "@reduxjs/toolkit";
import {
  getSessionStorage,
  setSessionStorage,
} from "../../utils/session-storage";
import { IGrapgh } from "../../views/interfaces";

const graphData: IGrapgh = getSessionStorage("graph");

const initialState: IGrapgh = {
  "@odata.context": graphData?.["@odata.context"] || "",
  businessPhones: graphData?.businessPhones || [],
  displayName: graphData?.displayName || "",
  givenName: graphData?.givenName || "",
  jobTitle: graphData?.jobTitle || "",
  mail: graphData?.mail || "",
  mobilePhone: graphData?.mobilePhone || null,
  officeLocation: graphData?.officeLocation || null,
  preferredLanguage: graphData?.preferredLanguage || null,
  surname: graphData?.surname || "",
  userPrincipalName: graphData?.userPrincipalName || "",
  id: graphData?.id || "",
};

export const setGraph = createAction("setGraph", (args: IGrapgh) => {
  return {
    payload: args,
  };
});

export const graphReducer = createReducer(initialState, (builder) => {
  builder.addCase(setGraph, (state, action) => {
    state["@odata.context"] = action.payload["@odata.context"];
    state.businessPhones = action.payload.businessPhones;
    state.displayName = action.payload.displayName;
    state.givenName = action.payload.givenName;
    state.jobTitle = action.payload.jobTitle;
    state.mail = action.payload.mail;
    state.mobilePhone = action.payload.mobilePhone;
    state.officeLocation = action.payload.officeLocation;
    state.preferredLanguage = action.payload.preferredLanguage;
    state.surname = action.payload.surname;
    state.userPrincipalName = action.payload.userPrincipalName;
    state.id = action.payload.id;

    setSessionStorage("graph", action.payload);
  });
});
