import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import dayjs from "dayjs";
import { ReactComponent as Approve } from "../../../../assets/approve.svg";
import { ReactComponent as ArrowRightIconComponent } from "../../../../assets/arrow-right.svg";
import { ReactComponent as SaveIconComponent } from "../../../../assets/save.svg";
// import SparkleGif from "../../../../assets/sparkle.gif";
import _ from "lodash";
import { ReactComponent as XIcon } from "../../../../assets/x.svg";
import { Button } from "../../../../components/_wrapper/Button";
import { DatePicker } from "../../../../components/_wrapper/Date-Picker";
import { Drawer } from "../../../../components/_wrapper/Drawer";
import { Flex, Form, FormInstance } from "../../../../components/_wrapper/Form";
import { Input } from "../../../../components/_wrapper/Input";
import { Select } from "../../../../components/_wrapper/Select";
import { SelectTree } from "../../../../components/_wrapper/Select-Tree";
import { Switch } from "../../../../components/_wrapper/Switch";
import { Confirmation } from "../../../../components/Confirmation/Confirmation";
import { RejectCancelReason } from "../../../../components/Reject-Cancel-Reason";
import { SVGIcon } from "../../../../components/SVG-Icon";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import {
  cancelDemandForm,
  closeDemandByRmg,
  convertDemandForm,
  createJdUsingAI,
  demandApproveOrReject,
  demandOnHoldOrRelease,
  getDemandCreationData,
  saveSubmitDemandCreationForm,
  setCloseDrawer,
} from "../../../../redux/slice/demand";
import { DateFormat_DDMMMYYYY } from "../../../../views/constants";
import {
  ECloseDemandType,
  EDemandStatus,
  EDemandSubStatusExternal,
  EEmployementEmployeeCampus,
  EFulFillmentStatus,
} from "../../../../views/enums";
import {
  IDemandDropdown,
  IDemandMeta,
  ILabelValue,
  ISavedFormData,
  ISaveSubmitDemandForm,
  MapSkillSpecialization,
  TDemandForm,
  TDemandFormField,
} from "../../../../views/interfaces";
import "./Demand-Form.css";

export const defaultDropdownData: IDemandDropdown = {
  roleList: [],
  verticalList: [],
  subVerticalList: [],
  practiceList: [],
  locationList: [],
  legalEntitiesList: [],
  projectOpportunityList: [],
  accountList: [],
  specializationList: [],
  skillList: [],
  demandTypeList: [],
  employmentTypeList: [],
  employeeTypeList: [],
  campusTypeList: [],
  interviewerList: [],
  fulfillmentTypeList: [],
  supplyRisk: [],
  educationQualificationList: [],
  workModeList: [],
  mapEmployementEmployeeCampus: [],
  mapPracticeRoleLevelSpecialization: [],
  mapSkillSpecialization: [],
};

export const createSaveSubmitJson = (
  formType: TDemandForm,
  formInstance: FormInstance<TDemandFormField>,
  indiaEntity: string[],
  locationCountry: string,
  meta?: IDemandMeta,
  isProject: boolean = false
): ISaveSubmitDemandForm => {
  const requestBody: ISaveSubmitDemandForm = {
    meta: {
      initialSaveOrSubmit: false,
      inHouseDemand: meta?.inHouseDemand || formType == "new",
      fulfillmentUpdate: false,
      submit: false,
      update: true,
      alreadySubmitted: false,
      isSupplyRiskUpdated: false,
    },
    demandId: meta?.demandId && meta.demandId.length > 0 ? meta.demandId : null,
    defreqId: meta?.defreqId && meta.defreqId.length > 0 ? meta.defreqId : null,
    role: {
      id: formInstance.getFieldValue("role")?.value as number,
      name: formInstance.getFieldValue("role")?.label,
    },
    level: {
      id: formInstance.getFieldValue("level")?.value as number,
      name: formInstance.getFieldValue("level")?.label,
    },
    practice: {
      id: formInstance.getFieldValue("practice")?.value as number,
      name: formInstance.getFieldValue("practice")?.label,
    },
    vertical: {
      id: formInstance.getFieldValue("vertical")?.value as number,
      name: formInstance.getFieldValue("vertical")?.label,
    },
    subVertical: {
      id: formInstance.getFieldValue("subVertical")?.value as number,
      name: formInstance.getFieldValue("subVertical")?.label,
    },
    entity: {
      id: formInstance.getFieldValue("entity")?.value as number,
      name: formInstance.getFieldValue("entity")?.label,
    },
    account: {
      id: formInstance.getFieldValue("accountName")?.value as number,
      name: formInstance.getFieldValue("accountName")?.label,
    },
    project: isProject
      ? {
          id: formInstance.getFieldValue("projectOpportunity")?.value as number,
          name: formInstance.getFieldValue("projectOpportunity")?.label,
        }
      : { id: meta?.project.id, name: meta?.project.name },
    opportunity: !isProject
      ? {
          id: formInstance.getFieldValue("projectOpportunity")?.value as number,
          name: formInstance.getFieldValue("projectOpportunity")?.label,
        }
      : { id: meta?.opportunity.id, name: meta?.opportunity.name },
    location: {
      id: formInstance.getFieldValue("location")?.value as number,
      name: formInstance.getFieldValue("location")?.label,
      onsiteOffshore: indiaEntity.includes(locationCountry)
        ? "Offshore"
        : "Onsite",
    },
    specialization: {
      id: formInstance.getFieldValue("specialization")?.value,
      name: formInstance.getFieldValue("specialization")?.label,
    },
    skillPrimary:
      formInstance.getFieldValue("skillPrimary")?.map((item: any) => {
        return { id: item?.value as number, name: item?.label as string };
      }) ?? [],
    skillSecondary:
      formInstance.getFieldValue("skillSecondary")?.map((item: any) => {
        return { id: item?.value as number, name: item?.label as string };
      }) ?? [],
    startDate: formInstance.getFieldValue("startDate")?.toString() ?? "",
    endDate: formInstance.getFieldValue("endDate")?.toString() ?? "",
    startDateLeadTime: formInstance.getFieldValue("startDateLeadTime")
      ? dayjs(formInstance.getFieldValue("startDateLeadTime")).format(
          DateFormat_DDMMMYYYY
        )
      : "",
    supplyRisk: {
      id: formInstance.getFieldValue("supplyRisk")?.value ?? "No",
      name: formInstance.getFieldValue("supplyRisk")?.label ?? "No",
    },
    supplyRiskDate:
      formInstance
        .getFieldValue("supplyRisk")
        ?.value?.toString()
        ?.toLowerCase() == "yes"
        ? formInstance.getFieldValue("supplyRiskDate")?.toString() ?? ""
        : "",
    isBillable: !!formInstance.getFieldValue("isBillable"),
    billRate:
      formInstance.getFieldValue("isBillable") &&
      formInstance.getFieldValue("billRate")
        ? parseFloat(formInstance.getFieldValue("billRate")?.toString())
        : 0,
    alloactionPercentage:
      formInstance.getFieldValue("alloactionPercentage") ?? 0,
    demandType: {
      id: formInstance.getFieldValue("demandType")?.value,
      name: formInstance.getFieldValue("demandType")?.label,
    },
    employmentType: {
      id: formInstance.getFieldValue("employmentType")?.value,
      name: formInstance.getFieldValue("employmentType")?.label,
    },
    interviewLevel1: {
      id: formInstance.getFieldValue("interviewLevel1")?.value,
      name: formInstance.getFieldValue("interviewLevel1")?.label,
    },
    interviewLevel2: {
      id: formInstance.getFieldValue("interviewLevel2")?.value,
      name: formInstance.getFieldValue("interviewLevel2")?.label,
    },
    interviewClient:
      formInstance.getFieldValue("isClientInterviewRequired") &&
      formInstance.getFieldValue("interviewClient")
        ? formInstance.getFieldValue("interviewClient")
        : "",
    jobDescription: formInstance.getFieldValue("jobDescription") ?? "",
    fulfillmentType: {
      id: formInstance.getFieldValue("fulfillmentType")?.value,
      name: formInstance.getFieldValue("fulfillmentType")?.label,
    },
    additionalInstruction:
      formInstance.getFieldValue("additionalInstructions") ?? "",
    educationQualification: {
      id: formInstance.getFieldValue("educationDetails")?.value,
      name: formInstance.getFieldValue("educationDetails")?.label,
    },
    hiringManager: {
      id: formInstance.getFieldValue("hiringManager")?.value,
      name: formInstance.getFieldValue("hiringManager")?.label,
    },
    employeeType: {
      id: formInstance.getFieldValue("employeeType")?.value,
      name: formInstance.getFieldValue("employeeType")?.label,
    },
    campusType: {
      id: formInstance.getFieldValue("campusType")?.value,
      name: formInstance.getFieldValue("campusType")?.label,
    },
    workMode: {
      id: formInstance.getFieldValue("workMode")?.value,
      name: formInstance.getFieldValue("workMode")?.label,
    },
    noOfPosition:
      formType == "new" || meta?.inHouseDemand
        ? formInstance.getFieldValue("noOfPosition")
        : undefined,
    experienceMin: formInstance.getFieldValue("experienceMin") ?? 0,
    experienceMax: formInstance.getFieldValue("experienceMax") ?? 0,
  };
  return requestBody;
};

export const createInitiatalValues = (
  saveDemandData: ISavedFormData | null
) => {
  let initiatalValues: TDemandFormField;
  if (saveDemandData) {
    initiatalValues = {
      role: {
        label: saveDemandData?.role?.name as string,
        value: saveDemandData?.role?.id as number,
      },
      level: {
        label: saveDemandData?.level,
        value: saveDemandData?.level,
      },
      practice: {
        label: saveDemandData?.practice?.name as string,
        value: saveDemandData?.practice?.id as number,
      },
      vertical: {
        label: saveDemandData?.vertical?.name as string,
        value: saveDemandData?.vertical?.id as number,
      },
      subVertical: {
        label: saveDemandData?.subVertical?.name as string,
        value: saveDemandData?.subVertical?.id as number,
      },
      location: {
        label: saveDemandData?.location?.name as string,
        value: saveDemandData?.location?.id as number,
      },
      entity: {
        label: saveDemandData?.legalEntity?.name as string,
        value: saveDemandData?.legalEntity?.id as number,
      },
      accountName: {
        label: saveDemandData?.account?.name as string,
        value: saveDemandData?.account?.id as number,
      },
      projectOpportunity: {
        label:
          saveDemandData?.project?.name &&
          saveDemandData?.project?.name.length > 0
            ? saveDemandData?.project?.name
            : (saveDemandData?.opportunity?.name as string),
        value: saveDemandData?.project?.id
          ? saveDemandData?.project?.id
          : (saveDemandData?.opportunity?.id as number),
      },
      specialization: {
        label: saveDemandData?.specialization?.name as string,
        value: saveDemandData?.specialization?.id as number,
      },
      skillPrimary: saveDemandData?.primarySkills?.map((skill: any) => {
        return { label: skill?.name, value: skill?.id };
      }),
      skillSecondary: saveDemandData?.secondarySkills?.map((skill: any) => {
        return { label: skill?.name, value: skill?.id };
      }),
      startDate: saveDemandData?.startDate
        ? dayjs(saveDemandData?.startDate)
        : undefined,
      endDate: saveDemandData?.endDate
        ? dayjs(saveDemandData?.endDate)
        : undefined,
      startDateLeadTime: saveDemandData?.startDateLeadTime
        ? dayjs(saveDemandData?.startDateLeadTime).format(DateFormat_DDMMMYYYY)
        : undefined,
      supplyRisk: {
        label: saveDemandData?.isSuplyRisk ? "Yes" : "No",
        value: saveDemandData?.isSuplyRisk ? "Yes" : "No",
      },
      supplyRiskDate: saveDemandData?.suplyRiskDate
        ? dayjs(saveDemandData?.suplyRiskDate)
        : undefined,
      isBillable: saveDemandData?.isBillable,
      billRate: saveDemandData?.billRate ?? 0,
      alloactionPercentage: saveDemandData?.alloactionPercentage,
      demandType: {
        label: saveDemandData?.demandType?.name as string,
        value: saveDemandData?.demandType?.id as string,
      },
      employmentType: {
        label: saveDemandData?.employmentType?.name as string,
        value: saveDemandData?.employmentType?.id as string,
      },
      fulfillmentType: {
        label:
          (saveDemandData?.fulfillmentType?.name as string) ??
          EFulFillmentStatus.External,
        value:
          (saveDemandData?.fulfillmentType?.id as string) ??
          EFulFillmentStatus.External,
      },
      interviewLevel1: {
        label: saveDemandData?.interviewLevel1?.name as string,
        value: saveDemandData?.interviewLevel1?.id as number,
      },
      interviewLevel2: {
        label: saveDemandData?.interviewLevel2?.name as string,
        value: saveDemandData?.interviewLevel2?.id as number,
      },
      interviewClient: saveDemandData?.interviewClient,
      isClientInterviewRequired:
        saveDemandData?.interviewClient?.length > 0 ? true : false,
      jobDescription: saveDemandData?.jobDescription,
      additionalInstructions: saveDemandData?.additionalInstructions,
      educationDetails: {
        label: saveDemandData?.educationQualification?.name as string,
        value: saveDemandData?.educationQualification?.id as number,
      },
      hiringManager: {
        label: saveDemandData?.hiringManager?.name as string,
        value: saveDemandData?.hiringManager?.id as number,
      },
      employeeType: {
        label: (saveDemandData?.employeeType?.name as string) ?? "NA",
        value: (saveDemandData?.employeeType?.id as string) ?? "NA",
      },
      campusType: {
        label: (saveDemandData?.campusType?.name as string) ?? "NA",
        value: (saveDemandData?.campusType?.id as string) ?? "NA",
      },
      workMode: {
        label: saveDemandData?.workMode?.name as string,
        value: saveDemandData?.workMode?.id as string,
      },
      noOfPosition: saveDemandData?.noOfPosition ?? 1,
      experienceMin: saveDemandData?.experienceMin,
      experienceMax: saveDemandData?.experienceMax,
    };
  } else {
    initiatalValues = {
      supplyRisk: {
        label: "No",
        value: "No",
      },
      billRate: 0,
      alloactionPercentage: 100,
      isClientInterviewRequired: false,
      isBillable: false,
      employeeType: {
        label: "NA",
        value: "NA",
      },
      campusType: {
        label: "NA",
        value: "NA",
      },
      noOfPosition: 1,
      experienceMin: 0,
      fulfillmentType: {
        label: EFulFillmentStatus.External,
        value: EFulFillmentStatus.External,
      },
    };
  }
  return initiatalValues;
};

export const customizeRequiredMark = (
  label: React.ReactNode,
  { required }: { required: boolean }
) => (
  <>
    {typeof label === "string" ? (
      <>
        {label}{" "}
        {required ? <span className="field-required-mark"> *</span> : <></>}
      </>
    ) : (
      label
    )}
  </>
);

export interface DemandFormProps {
  formType?: TDemandForm;
  meta?: IDemandMeta;
}

export const DemandForm: React.FC<DemandFormProps> = ({
  formType = "draft",
  meta,
}) => {
  const dispatch = useAppDispatch();
  const { dropdownData, saveDemandData, jobDescription, openDemandPermission } =
    useAppSelector((state) => state.demand);
  const indiaEntity = useMemo(() => {
    return ["IND"];
  }, []);
  const locationCountry = useRef("");
  const isProject = useRef(false);
  const aiGeneratedJd = useRef(false);
  const [formInstance] = Form.useForm<TDemandFormField>();
  const values = Form.useWatch([], formInstance);
  const [dropdown, setDropdown] =
    useState<IDemandDropdown>(defaultDropdownData);
  const [initialValues, setInitialValues] = useState<TDemandFormField>(
    {} as TDemandFormField
  );
  const [openCancellation, setOpenCancellation] = useState<boolean>(false);
  const [openCancellationModal, setOpenCancellationModal] =
    useState<boolean>(false);
  const [closeByRmg, setCloseByRmg] = useState<boolean>(false);
  const [leftButtonDisabled, setLeftButtonDisabled] = useState<boolean>(false);
  const [centerButtonDisabled, setCenterButtonDisabled] = useState<boolean>(
    formType == "new" ? true : false
  );
  const [rightButtonDisabled, setRightButtonDisabled] = useState<boolean>(true);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [initialDisable, setInitialDisable] = useState<boolean>(true);

  useEffect(() => {
    setDropdown({ ...dropdownData });
  }, [dropdownData]);

  useEffect(() => {
    if (formType == "new" || meta?.demandId || meta?.defreqId) {
      dispatch(
        getDemandCreationData({
          type: formType,
          demandId: meta?.demandId,
          defreqId: meta?.defreqId,
        })
      );
    }
  }, [formType, meta?.demandId, meta?.defreqId, dispatch]);

  useEffect(() => {
    setInitialValues(createInitiatalValues(saveDemandData));
  }, [saveDemandData]);

  useEffect(() => {
    formInstance
      .validateFields({ validateOnly: true })
      .then(() => {
        setRightButtonDisabled(false);
        if (formType == "new") {
          setCenterButtonDisabled(false);
        }
      })
      .catch(() => {
        setRightButtonDisabled(true);
        if (formType == "new") {
          setCenterButtonDisabled(true);
        }
      });
  }, [formInstance, formType, values]);

  const generateAIJobDescription = async () => {
    const requestBody = {
      role: formInstance.getFieldValue("role")?.label,
      vertical: formInstance.getFieldValue("vertical")?.label,
      level: formInstance.getFieldValue("level")?.label,
      specializations: formInstance.getFieldValue("specialization")?.label,
      skill: [
        ...(formInstance.getFieldValue("skillPrimary") || []),
        ...(formInstance.getFieldValue("skillSecondary") || []),
      ]
        .map((skill) => skill.label)
        .filter(Boolean)
        .join(", "),
      location: formInstance.getFieldValue("location")?.label,
      work_mode: formInstance.getFieldValue("workMode")?.label,
      education: formInstance.getFieldValue("educationDetails")?.label,
      min_experience: formInstance.getFieldValue("experienceMin"),
      max_experience: formInstance.getFieldValue("experienceMax"),
    };
    dispatch(createJdUsingAI(requestBody));
    aiGeneratedJd.current = true;
  };

  useEffect(() => {
    if (jobDescription && jobDescription.length > 0 && aiGeneratedJd.current) {
      formInstance.setFieldsValue({ jobDescription: jobDescription });
      aiGeneratedJd.current = false;
    }
  }, [jobDescription, formInstance]);

  const fetchPredefinedTemplates = () => {};

  const updateDemandStatus = () => {
    dispatch(
      convertDemandForm({
        id:
          meta?.demandId && meta.demandId.length > 0
            ? meta.demandId
            : meta?.defreqId,
        currentStatus: meta?.demandStatus.fulfillmentType,
      })
    );
  };

  const cancelCurrentDemandForm = (reason: string | null) => {
    if (reason) {
      dispatch(
        cancelDemandForm({
          defreqId: meta?.defreqId,
          demandId: meta?.demandId,
          cancellationReason: reason,
          fulfillmentType: meta?.demandStatus.fulfillmentType,
        })
      );
    }
  };

  const closeDemandFormByRmg = (reason: string | null) => {
    if (reason) {
      dispatch(
        closeDemandByRmg({
          defreqId: meta?.defreqId,
          closingReason: reason,
          closedType: ECloseDemandType.rmg,
        })
      );
    }
  };

  const saveSubmitForm = (submit: boolean = false) => {
    const requestBody = createSaveSubmitJson(
      formType,
      formInstance,
      indiaEntity,
      locationCountry.current,
      meta,
      isProject.current
    );
    requestBody.meta.initialSaveOrSubmit =
      saveDemandData?.defreqId && saveDemandData?.defreqId?.length > 0
        ? false
        : true;
    requestBody.meta.submit = submit;
    requestBody.meta.fulfillmentUpdate =
      saveDemandData?.fulfillmentType?.id !=
      formInstance.getFieldValue("fulfillmentType")?.value
        ? true
        : false;
    requestBody.meta.isSupplyRiskUpdated = (
      formInstance
        .getFieldValue("supplyRisk")
        ?.value?.toString()
        .toLowerCase() != saveDemandData?.isSuplyRisk
        ? "yes"
        : "no"
    )
      ? true
      : false;
    requestBody.meta.alreadySubmitted = saveDemandData?.isSubmitted || false;
    if (!submit) {
      requestBody.startDateLeadTime = undefined;
    }
    dispatch(saveSubmitDemandCreationForm(requestBody));
  };

  const onSelectProject = useCallback(
    (option: any, resetRelatedFields: boolean = true) => {
      setDropdown((prev) => {
        return {
          ...prev,
          subVerticalList: dropdownData?.subVerticalList?.filter(
            (subVertical: any) =>
              subVertical?.parentId == option?.verticalInternalId
          ),
        };
      });
      if (resetRelatedFields) {
        formInstance.setFieldsValue({
          accountName: dropdownData?.accountList?.find(
            (item) => item.value == option?.accountInternalId
          ),
          vertical: dropdownData?.verticalList?.find(
            (item) => item.value == option?.verticalInternalId
          ),
          subVertical: undefined,
        });
      }
      isProject.current = option.id == 1 ? true : false; // id 1 is project, id 2 is opportunity
    },
    [formInstance, dropdownData]
  );

  const onSelectPractice = useCallback(
    (option: ILabelValue, resetRelatedFields: boolean = true) => {
      let filteredLevelRolePracticeSpecialization =
        dropdownData?.mapPracticeRoleLevelSpecialization?.filter((item) => {
          return item.practice.trim() == option?.label?.trim();
        });
      setDropdown((prev) => {
        return {
          ...prev,
          roleList: dropdownData?.roleList?.filter((role) => {
            return filteredLevelRolePracticeSpecialization?.some((item) => {
              return item.role_title?.trim() == role?.label?.trim();
            });
          }),
        };
      });
      if (resetRelatedFields) {
        formInstance.setFieldsValue({
          role: undefined,
          level: undefined,
          specialization: undefined,
          skillPrimary: undefined,
        });
      }
    },
    [formInstance, dropdownData]
  );

  const onSelectRole = useCallback(
    (option: any, resetRelatedFields: boolean = true) => {
      const isIntern =
        option?.label?.trim().toLowerCase() ==
        EEmployementEmployeeCampus.intern;
      const isContractor =
        option?.label?.trim().toLowerCase() ==
        EEmployementEmployeeCampus.contractor;
      const specializationSet = new Set<string>();
      // Filter based on selected role, practice, and level (or contractor)
      dropdownData?.mapPracticeRoleLevelSpecialization?.forEach((item) => {
        if (
          isContractor &&
          item.practice.trim() ===
            formInstance.getFieldValue("practice")?.label?.trim()
        ) {
          specializationSet.add(item.specialization?.trim());
        } else if (
          item.practice.trim() ===
            formInstance.getFieldValue("practice")?.label?.trim() &&
          item.role_title?.trim() === option?.label?.trim() &&
          item.level?.trim() === option?.level?.trim()
        ) {
          specializationSet.add(item.specialization?.trim());
        }
      });
      setDropdown((prev) => ({
        ...prev,
        specializationList: dropdownData?.specializationList?.filter(
          (specialization) =>
            Array.from(specializationSet).includes(specialization.label?.trim())
        ),
        employmentTypeList: isIntern
          ? dropdownData?.employmentTypeList?.filter((item) => {
              return (
                item.value.toString().toLowerCase() ===
                EEmployementEmployeeCampus.intern
              );
            })
          : isContractor
          ? dropdownData?.employmentTypeList?.filter((item) => {
              return item.value
                .toString()
                .toLowerCase()
                .includes(EEmployementEmployeeCampus.contractor);
            })
          : dropdownData?.employmentTypeList?.filter(
              (item) =>
                item.value.toString().toLowerCase() ==
                EEmployementEmployeeCampus.employee
            ),
      }));
      if (resetRelatedFields) {
        formInstance.setFieldsValue({
          level: option
            ? { label: option?.level, value: option?.level }
            : undefined,
          specialization: undefined,
          skillPrimary: undefined,
          employmentType:
            isIntern || isContractor
              ? undefined
              : {
                  label: formInstance.getFieldValue("employmentType")?.label,
                  value: formInstance.getFieldValue("employmentType")?.value,
                },
          employeeType:
            isIntern || isContractor
              ? { label: "NA", value: "NA" }
              : {
                  label: formInstance.getFieldValue("employeeType")?.label,
                  value: formInstance.getFieldValue("employeeType")?.value,
                },
          campusType:
            isIntern || isContractor
              ? { label: "NA", value: "NA" }
              : {
                  label: formInstance.getFieldValue("campusType")?.label,
                  value: formInstance.getFieldValue("campusType")?.label,
                },
        });
      }
    },
    [formInstance, dropdownData]
  );

  const onSelectSpecialization = useCallback(
    (option: ILabelValue, resetRelatedFields: boolean = true) => {
      const isContractor =
        formInstance.getFieldValue("role")?.label?.trim()?.toLowerCase() ==
        EEmployementEmployeeCampus.contractor;
      let filteredSkillSpecialization: MapSkillSpecialization[] = [];
      if (isContractor) {
        // Find the highest level for the current practice
        const highestLevel = dropdownData?.mapPracticeRoleLevelSpecialization
          ?.filter((item) => {
            return (
              item.practice.trim() ===
                formInstance.getFieldValue("practice")?.label?.trim() &&
              item.specialization?.trim() === option?.label?.trim()
            );
          })
          ?.reduce((max, item) => {
            const levelAsNumber = Number(item.level.replace(/^\D+/g, ""));
            return levelAsNumber > max ? levelAsNumber : max;
          }, 0);
        // Find all specializations linked to the highest level
        const filteredLevelRolePracticeSpecialization =
          dropdownData?.mapPracticeRoleLevelSpecialization?.filter((item) => {
            return (
              item.practice.trim() ===
                formInstance.getFieldValue("practice")?.label?.trim() &&
              Number(item.level.replace(/^\D+/g, "")) === highestLevel &&
              item.specialization?.trim() === option?.label?.trim()
            );
          });

        filteredSkillSpecialization =
          dropdownData?.mapSkillSpecialization?.filter((item) => {
  
            return filteredLevelRolePracticeSpecialization?.some(
              (_item) => _item.specialization?.trim() == item.specialization?.trim()
              && Number(item.level.replace(/^\D+/g, "")) === highestLevel
              && item.practice?.trim() === _item.practice?.trim()
            )
          });
      } else {
        filteredSkillSpecialization =
          dropdownData?.mapSkillSpecialization?.filter((item) => {
            return (
              item.specialization?.trim() === option?.label?.trim()
              && item.level === formInstance.getFieldValue("level")?.value
              && item.practice?.trim() === formInstance.getFieldValue("practice")?.label?.trim()
              && item.role_title?.trim() === formInstance.getFieldValue("role")?.label?.trim()
            );
          });
      }
      if (resetRelatedFields) {
        formInstance.setFieldsValue({
          skillPrimary: dropdownData?.skillList?.filter((skill: any) => {
            return filteredSkillSpecialization?.some((item) => {
              return item.skill_internal_id === skill.value;
            });
          }),
        });
      }
    },
    [formInstance, dropdownData]
  );

  const onSelectLocation = useCallback(
    (option: any, resetRelatedFields: boolean = true) => {
      setDropdown((prev) => {
        return {
          ...prev,
          educationQualificationList:
            dropdownData?.educationQualificationList?.filter((item: any) =>
              dropdownData?.educationQualificationList?.some(
                (i: any) => i?.country === option?.country
              )
                ? item?.country === option?.country
                : !item?.country || item?.country === null
            ),
          legalEntitiesList: dropdownData?.legalEntitiesList?.filter(
            (item: any) => {
              return item?.country == option?.country;
            }
          ),
        };
      });
      if (resetRelatedFields && !(formType == "open")) {
        formInstance.setFieldsValue({
          entity:  undefined,
          educationDetails: undefined,
          employmentType: undefined,
          employeeType: { label: "NA", value: "NA" },
          campusType: { label: "NA", value: "NA" },
        });
      }
      locationCountry.current = option.country;
    },
    [formInstance, dropdownData, formType]
  );

  const onSelectEmploymentType = useCallback(
    (option: ILabelValue, resetRelatedFields: boolean = true) => {
      const filteredEmploymentEmployeeCampus =
        dropdownData?.mapEmployementEmployeeCampus?.filter((item) => {
          return item.employment_type == option?.value;
        });
      setDropdown((prev) => {
        return {
          ...prev,
          employeeTypeList: dropdownData?.employeeTypeList?.filter((item) => {
            return filteredEmploymentEmployeeCampus?.some((emp) => {
              return emp.employee_type == item.value;
            });
          }),
        };
      });
      if (resetRelatedFields) {
        formInstance.setFieldsValue({
          employeeType:
            option?.value?.toString().toLowerCase() ==
            EEmployementEmployeeCampus.employee
              ? undefined
              : { label: "NA", value: "NA" },
          campusType: { label: "NA", value: "NA" },
        });
      }
    },
    [formInstance, dropdownData]
  );

  const onSelectEmployeeType = useCallback(
    (option: ILabelValue, resetRelatedFields: boolean = true) => {
      const filteredEmploymentEmployeeCampus =
        dropdownData?.mapEmployementEmployeeCampus?.filter((item) => {
          return item.employee_type == option?.value;
        });
      setDropdown((prev) => {
        return {
          ...prev,
          campusTypeList: dropdownData?.campusTypeList?.filter((item) => {
            return filteredEmploymentEmployeeCampus?.some((emp) => {
              return emp.campus_type == item.value;
            });
          }),
        };
      });
      if (resetRelatedFields) {
        formInstance.setFieldsValue({
          campusType:
            option?.value?.toString().toLowerCase() ==
              EEmployementEmployeeCampus.campus &&
            indiaEntity.includes(locationCountry.current)
              ? undefined
              : { label: "NA", value: "NA" },
        });
      }
    },
    [formInstance, indiaEntity, locationCountry, dropdownData]
  );

  const onSelectSupplyRisk = useCallback(
    (option: ILabelValue, resetRelatedFields: boolean = true) => {
      if (resetRelatedFields) {
        formInstance.setFieldsValue({
          supplyRiskDate:
            option?.value?.toString()?.toLowerCase() == "yes"
              ? dayjs(new Date()).format(DateFormat_DDMMMYYYY)
              : undefined,
        });
      }
    },
    [formInstance]
  );

  const approveOrRejectOpenDemand = (isApproved: boolean) => {
    dispatch(
      demandApproveOrReject({
        body: {
          id: [meta?.defreqId],
          is_approved: isApproved,
        },
      })
    );
  };

  const onHoldOrRelease = (onHold: boolean) => {
    dispatch(
      demandOnHoldOrRelease({
        body: {
          id: meta?.defreqInternalId,
          defreqId: meta?.defreqId,
          fulfillmentType: meta?.demandStatus.fulfillmentType,
          hold: onHold,
        },
      })
    );
  };

  const handleValueChange = () => {
    setInitialDisable(false);
  };

  const setFieldDropdownOnSaveDemandData = useCallback(() => {
    if (saveDemandData) {
      setLeftButtonDisabled(saveDemandData?.fulfillmentType ? false : true);
      if (saveDemandData?.practice?.name && saveDemandData?.practice?.id) {
        onSelectPractice(
          {
            label: saveDemandData?.practice?.name,
            value: saveDemandData?.practice?.id,
          },
          false
        );
      }
      if (saveDemandData?.role?.name && saveDemandData?.role?.id) {
        onSelectRole(
          {
            label: saveDemandData?.role?.name,
            value: saveDemandData?.role?.id,
            level: saveDemandData?.level,
          },
          false
        );
      }
      if (
        saveDemandData?.specialization?.name &&
        saveDemandData?.specialization?.id
      ) {
        onSelectSpecialization(
          {
            label: saveDemandData?.specialization?.name,
            value: saveDemandData?.specialization?.id,
          },
          false
        );
      }
      if (saveDemandData?.location?.name && saveDemandData?.location?.id) {
        onSelectLocation(
          {
            label: saveDemandData?.location?.name,
            value: saveDemandData?.location?.id,
            country: dropdownData?.locationList?.find(
              (item) => item.value == saveDemandData?.location?.id
            )?.country,
          },
          false
        );
      }
      if (
        saveDemandData?.employmentType?.name &&
        saveDemandData?.employmentType?.id
      ) {
        onSelectEmploymentType(
          {
            label: saveDemandData?.employmentType?.name,
            value: saveDemandData?.employmentType?.id,
          },
          false
        );
      }
      if (
        saveDemandData?.employeeType?.name &&
        saveDemandData?.employeeType?.id
      ) {
        onSelectEmployeeType(
          {
            label: saveDemandData?.employeeType?.name,
            value: saveDemandData?.employeeType?.id,
          },
          false
        );
      }
    }
  }, [
    saveDemandData,
    dropdownData.locationList,
    onSelectPractice,
    onSelectRole,
    onSelectSpecialization,
    onSelectLocation,
    onSelectEmploymentType,
    onSelectEmployeeType,
  ]);

  useEffect(() => {
    if (!_.isEmpty(initialValues)) {
      formInstance.resetFields();
      if (["open"].includes(formType)) {
        setFieldDropdownOnSaveDemandData();
      }
    }
  }, [initialValues, formInstance, formType, setFieldDropdownOnSaveDemandData]);

  return (
    <Fragment>
      <div className="demand-form">
        <Form
          layout="vertical"
          form={formInstance}
          name="validateOnly"
          autoComplete="off"
          requiredMark={customizeRequiredMark}
          initialValues={initialValues}
          scrollToFirstError={true}
          disabled={formDisabled}
          onValuesChange={handleValueChange}
        >
          <div className="demand-form-view">
            <div className="demand-form-section">
              <p className="demand-from-section-level">
                {formType == "new" ||
                (formType == "draft" && meta?.inHouseDemand)
                  ? "Job Information"
                  : "General"}
              </p>
              <div className="demand-form-section-content">
                {(formType == "new" ||
                  (formType == "draft" && meta?.inHouseDemand)) && (
                  <>
                    <Flex
                      className="practice-project"
                      justify="space-between"
                      gap={16}
                    >
                      <Form.Item<TDemandFormField>
                        label="Practice"
                        name="practice"
                        rules={[
                          {
                            required: true,
                            message: "Please select practice!",
                          },
                        ]}
                      >
                        <Select
                          id="practice"
                          placeholder="Select Practice"
                          options={dropdown.practiceList}
                          onChange={(value) => onSelectPractice(value)}
                        />
                      </Form.Item>
                      <Form.Item<TDemandFormField>
                        label="Opportunity/Project Name"
                        name="projectOpportunity"
                        rules={[
                          {
                            required: true,
                            message: "Please select Opportunity/Project name!",
                          },
                        ]}
                      >
                        <Select
                          id="projectOpportunity"
                          placeholder="Select Opportunity/Project Name"
                          options={dropdown.projectOpportunityList}
                          onChange={(_, option) => onSelectProject(option)}
                        />
                      </Form.Item>
                    </Flex>
                    <Flex
                      className="account-vertical"
                      justify="space-between"
                      gap={16}
                    >
                      <Form.Item<TDemandFormField>
                        label="Account Name"
                        name="accountName"
                        rules={[
                          {
                            required: true,
                            message: "Please select account name!",
                          },
                        ]}
                      >
                        <Select
                          id="accountName"
                          placeholder="Select Account Name"
                          options={dropdown.accountList}
                          disabled={true}
                        />
                      </Form.Item>
                      <Flex
                        className="vertical-subvertical"
                        style={{ width: "100%" }}
                        vertical={true}
                      >
                        <Form.Item<TDemandFormField>
                          label="Sub Vertical"
                          name="subVertical"
                          rules={[
                            {
                              required: true,
                              message: "Please select sub vertical!",
                            },
                          ]}
                        >
                          <Select
                            id="subVertical"
                            placeholder="Select Sub-Vertical"
                            options={dropdown.subVerticalList}
                            disabled={
                              !formInstance.getFieldValue("projectOpportunity")
                                ?.value
                            }
                          />
                        </Form.Item>
                        <Form.Item<TDemandFormField> name="vertical">
                          {formInstance.getFieldValue("vertical")?.value && (
                            <p>
                              Vertical:{" "}
                              <span className="form-label-extra">
                                {formInstance.getFieldValue("vertical")?.label}
                              </span>
                            </p>
                          )}
                        </Form.Item>
                      </Flex>
                    </Flex>
                  </>
                )}
                <Flex
                  className="role-level-specialization"
                  justify="space-between"
                  gap={16}
                >
                  <Flex
                    className="role-level"
                    style={{ width: "100%" }}
                    vertical={true}
                  >
                    <Form.Item<TDemandFormField>
                      label="Role"
                      name="role"
                      rules={[
                        { required: true, message: "Please select role!" },
                      ]}
                    >
                      <Select
                        id="role"
                        placeholder="Select Role"
                        options={dropdown.roleList}
                        onChange={(_, option) => onSelectRole(option)}
                        disabled={
                          !formInstance.getFieldValue("practice")?.value
                        }
                      />
                    </Form.Item>
                    <Form.Item<TDemandFormField> name="level">
                      {formInstance.getFieldValue("level")?.value && (
                        <div>
                          <p>
                            Level:{" "}
                            <span className="form-label-extra">
                              {formInstance.getFieldValue("level")?.label}
                            </span>
                          </p>
                        </div>
                      )}
                    </Form.Item>
                  </Flex>
                  <Form.Item<TDemandFormField>
                    label="Specialization"
                    name="specialization"
                    rules={[
                      {
                        required: true,
                        message: "Please select specialization!",
                      },
                    ]}
                  >
                    <Select
                      id="specialization"
                      placeholder="Select Specialization"
                      options={dropdown.specializationList}
                      onChange={(_, option) => onSelectSpecialization(option)}
                      disabled={
                        formType == "new" &&
                        !formInstance.getFieldValue("role")?.value
                      }
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="primary-secondary-skills"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Primary Skills"
                    name="skillPrimary"
                    rules={[
                      {
                        required: true,
                        message: "Please select primary skill!",
                      },
                    ]}
                  >
                    <Select
                      id="skillPrimary"
                      placeholder="Select Skills"
                      options={dropdown.skillList}
                      multiple={true}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Secondary Skills"
                    name="skillSecondary"
                  >
                    <SelectTree
                      id="skillSecondary"
                      placeholder="Select Skills"
                      treeData={
                        formInstance.getFieldValue("skillPrimary")
                          ? dropdown.skillList.filter(
                              (skill) =>
                                formInstance
                                  .getFieldValue("skillPrimary")
                                  ?.findIndex(
                                    (item: any) => item.value == skill.value
                                  ) == -1
                            )
                          : dropdown.skillList
                      }
                      showCheckedStrategy="SHOW_ALL"
                      multiple={true}
                      rootClassName="secondary-skill"
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="location-work-mode"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Location"
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: "Please select location!",
                      },
                    ]}
                  >
                    <Select
                      id="location"
                      placeholder="Select Location"
                      options={dropdown.locationList}
                      onChange={(_, option) => onSelectLocation(option)}
                    />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Work Mode"
                    name="workMode"
                    rules={[
                      {
                        required: true,
                        message: "Please select work mode!",
                      },
                    ]}
                  >
                    <Select
                      id="workMode"
                      placeholder="Select Work Mode"
                      options={dropdown.workModeList}
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="education-entity"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Education Details"
                    name="educationDetails"
                    rules={[
                      {
                        required: true,
                        message: "Please select education details!",
                      },
                    ]}
                    style={{ width: 752 }}
                  >
                    <Select
                      id="educationDetails"
                      placeholder="Select education details"
                      options={dropdown.educationQualificationList}
                      disabled={!formInstance.getFieldValue("location")?.value}
                    />
                  </Form.Item>
                  {(formType == "new" ||
                    (formType == "draft" && meta?.inHouseDemand)) && (
                    <Form.Item<TDemandFormField>
                      label="Entity"
                      name="entity"
                      rules={[
                        { required: true, message: "Please select entity!" },
                      ]}
                    >
                      <Select
                        id="entity"
                        placeholder="Select Entity"
                        options={dropdown.legalEntitiesList}
                        disabled={
                          !formInstance.getFieldValue("location")?.value
                        }
                      />
                    </Form.Item>
                  )}
                </Flex>
                <Flex
                  className="experience-min-max"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Minimum Years of Experience"
                    name="experienceMin"
                    rules={[
                      {
                        required: true,
                        message: "Please enter minimum years of experience!",
                      },
                    ]}
                  >
                    <Input
                      id="experienceMin"
                      placeholder="Enter Minimum Years of Experience"
                      htmlType="number"
                      min={0}
                      max={formInstance.getFieldValue("experienceMax")}
                    />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Maximum Years of Experience"
                    name="experienceMax"
                    rules={[
                      {
                        required: true,
                        message: "Please enter minimum years of experience!",
                      },
                    ]}
                  >
                    <Input
                      id="experienceMax"
                      placeholder="Enter Maximum Years of Experience"
                      htmlType="number"
                      min={formInstance.getFieldValue("experienceMin")}
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="start-end-date"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Start Date"
                    name="startDate"
                    rules={[
                      { required: true, message: "Please select start date!" },
                    ]}
                  >
                    <DatePicker
                      id="startDate"
                      placeholder="Select Start Date"
                      maxDate={
                        formInstance.getFieldValue("endDate")
                          ? dayjs(
                              formInstance.getFieldValue("endDate")
                            ).subtract(1, "d")
                          : undefined
                      }
                      minDate={dayjs(new Date())}
                      disabled={formType == "open"}
                    />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="End Date"
                    name="endDate"
                    rules={[
                      { required: true, message: "Please select end date!" },
                    ]}
                  >
                    <DatePicker
                      id="endDate"
                      placeholder="Select End Date"
                      minDate={
                        formInstance.getFieldValue("startDate")
                          ? dayjs(formInstance.getFieldValue("startDate")).add(
                              1,
                              "d"
                            )
                          : dayjs(new Date()).add(1, "d")
                      }
                      disabled={formType == "open"}
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="supply-risk-date-start-end-lead-time"
                  justify="space-between"
                  gap={16}
                >
                  {formType == "open" && (
                    <Form.Item<TDemandFormField>
                      label="Start Date - Lead Time"
                      name="startDateLeadTime"
                      rules={[
                        {
                          required: false,
                          message: "Please select start date - lead time!",
                        },
                      ]}
                    >
                      <Input
                        id="startDateLeadTime"
                        placeholder="Start Date Lead Time"
                        disabled={true}
                      />
                    </Form.Item>
                  )}
                  {(formType == "new" ||
                    (formType == "draft" && meta?.inHouseDemand)) && (
                    <Form.Item<TDemandFormField>
                      label="No of Positions"
                      name="noOfPosition"
                      rules={[
                        {
                          required: true,
                          message: "Please enter no of position!",
                        },
                      ]}
                    >
                      <Input
                        id="noOfPosition"
                        htmlType="number"
                        placeholder="Enter No Of Position"
                        max={100}
                        min={1}
                      />
                    </Form.Item>
                  )}
                  <Flex
                    className="supply-risk-date"
                    style={{ width: "100%" }}
                    vertical={true}
                  >
                    <Form.Item<TDemandFormField>
                      label="Supply Risk"
                      name="supplyRisk"
                    >
                      <Select
                        id="supplyRisk"
                        placeholder="Select Supply Risk"
                        options={dropdown.supplyRisk}
                        onChange={(_, option) => onSelectSupplyRisk(option)}
                      />
                    </Form.Item>
                    <Form.Item<TDemandFormField> name="supplyRiskDate">
                      {formInstance.getFieldValue("supplyRiskDate") && (
                        <div>
                          Supply risk date is{" "}
                          <span style={{ color: "#fe5000" }}>
                            {dayjs(
                              formInstance.getFieldValue("supplyRiskDate")
                            ).format(DateFormat_DDMMMYYYY)}
                          </span>
                        </div>
                      )}
                    </Form.Item>
                  </Flex>
                </Flex>
              </div>
            </div>
            <div className="demand-form-section">
              <p className="demand-from-section-level">
                Allocation Information
              </p>
              <div className="demand-form-section-content">
                <Flex
                  className="isbillable-bill-rate"
                  vertical={true}
                  style={{ width: 368 }}
                >
                  <Form.Item<TDemandFormField>
                    name="isBillable"
                    label="Billable Demand"
                    className="billable-demand"
                  >
                    <Switch id="isBillable" />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Bill Rate"
                    name="billRate"
                    rules={[
                      {
                        required: !!formInstance.getFieldValue("isBillable"),
                        message: "Please enter bill rate!",
                      },
                    ]}
                  >
                    <Input
                      id="billRate"
                      placeholder="Enter Bill Rate"
                      htmlType="number"
                      disabled={!formInstance.getFieldValue("isBillable")}
                      min={0}
                      prefix="$"
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="allocation-hiring-manager"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Allocation"
                    name="alloactionPercentage"
                    rules={[
                      {
                        required: true,
                        message: "Please enter allocation percentage!",
                      },
                    ]}
                  >
                    <Input
                      id="alloactionPercentage"
                      placeholder="Enter Allocation Percentage"
                      htmlType="number"
                      min={1}
                      max={100}
                      suffix="%"
                      disabled={formType == "open"}
                    />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Hiring Manager"
                    name="hiringManager"
                    rules={[
                      {
                        required: true,
                        message: "Please enter allocation percentage!",
                      },
                    ]}
                  >
                    <Select
                      id="hiringManager"
                      placeholder="Select Hiring Manager"
                      options={dropdown.interviewerList}
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="demand-employment-type"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Demand Type"
                    name="demandType"
                    rules={[
                      {
                        required: true,
                        message: "Please select demand type!",
                      },
                    ]}
                  >
                    <Select
                      id="demandType"
                      placeholder="Select Demand Type"
                      options={dropdown.demandTypeList}
                    />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Employment Type"
                    name="employmentType"
                    rules={[
                      {
                        required: true,
                        message: "Please select employment type!",
                      },
                    ]}
                  >
                    <Select
                      id="employmentType"
                      placeholder="Select Employment Type"
                      options={dropdown.employmentTypeList}
                      onChange={(_, option) => onSelectEmploymentType(option)}
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="typeof-employment-typeof-campus"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Type of Employee"
                    name="employeeType"
                    rules={[
                      {
                        required:
                          formInstance
                            .getFieldValue("employmentType")
                            ?.value?.toString()
                            .toLowerCase() ==
                          EEmployementEmployeeCampus.employee,
                        message: "Please select type of employee!",
                      },
                    ]}
                  >
                    <Select
                      id="employeeType"
                      placeholder="Select Type Of Employee"
                      options={dropdown.employeeTypeList}
                      disabled={
                        formInstance
                          .getFieldValue("employmentType")
                          ?.value?.toString()
                          .toLowerCase() != EEmployementEmployeeCampus.employee
                      }
                      onChange={(_, option) => onSelectEmployeeType(option)}
                    />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Type Of Campus"
                    name="campusType"
                    rules={[
                      {
                        required:
                          indiaEntity.includes(locationCountry.current) &&
                          formInstance
                            .getFieldValue("employeeType")
                            ?.value?.toString()
                            .toLowerCase() == EEmployementEmployeeCampus.campus,
                        message: "Please select type of campus!",
                      },
                    ]}
                  >
                    <Select
                      id="campusType"
                      placeholder="Select Type Of Campus"
                      options={dropdown.campusTypeList}
                      disabled={
                        !indiaEntity.includes(locationCountry.current) ||
                        formInstance
                          .getFieldValue("employeeType")
                          ?.value?.toString()
                          .toLowerCase() != EEmployementEmployeeCampus.campus
                      }
                    />
                  </Form.Item>
                </Flex>
              </div>
            </div>
            <div className="demand-form-section">
              <p className="demand-from-section-level">Interview Information</p>
              <div className="demand-form-section-content">
                <Flex
                  className="interviewer-deatils"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Level 1 Interviewer"
                    name="interviewLevel1"
                    rules={[
                      { required: true, message: "Please select interviewer!" },
                    ]}
                  >
                    <Select
                      id="interviewLevel1"
                      placeholder="Select Interviewer"
                      options={dropdown.interviewerList}
                    />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Level 2 Interviewer"
                    name="interviewLevel2"
                  >
                    <Select
                      id="interviewLevel2"
                      placeholder="Select Interviewer"
                      options={dropdown.interviewerList}
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  className="client-interview"
                  justify="space-between"
                  vertical={true}
                  style={{ position: "relative" }}
                >
                  <Form.Item<TDemandFormField>
                    name="isClientInterviewRequired"
                    style={{
                      position: "absolute",
                      left: "120px",
                      zIndex: 2,
                    }}
                  >
                    <Switch id="isClientInterviewRequired" />
                  </Form.Item>
                  <Form.Item<TDemandFormField>
                    label="Client Interview"
                    name="interviewClient"
                    rules={[
                      {
                        required: formInstance.getFieldValue(
                          "isClientInterviewRequired"
                        ),
                        message: "Please enter client interviewer email id!",
                      },
                      {
                        type: "email",
                        message: "Please enter valid email id!",
                      },
                    ]}
                  >
                    {formInstance.getFieldValue(
                      "isClientInterviewRequired"
                    ) && (
                      <Input
                        htmlType="email"
                        id="interviewClient"
                        placeholder="Enter Interviewer Email ID"
                        disabled={
                          !formInstance.getFieldValue(
                            "isClientInterviewRequired"
                          )
                        }
                        autoComplete="off"
                      />
                    )}
                  </Form.Item>
                </Flex>
              </div>
            </div>
            <div className="demand-form-section">
              <div className="demand-form-section-content">
                <Flex
                  className="job-description"
                  justify="space-between"
                  vertical={true}
                  gap={16}
                  style={{ position: "relative", marginBottom: "16px" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      zIndex: 2,
                    }}
                  >
                    {/* <Button
                      type="link"
                      icon={
                        <img
                          src={SparkleGif}
                          height={18}
                          width={18}
                          alt="sparkle"
                        />
                      }
                      disabled={
                        formInstance.getFieldValue("role")?.label == null ||
                        formInstance.getFieldValue("vertical")?.label == null ||
                        formInstance.getFieldValue("specialization")?.label == null ||
                        formInstance.getFieldValue("skillPrimary") == null ||
                        formInstance.getFieldValue("location")?.label == null ||
                        formInstance.getFieldValue("workMode")?.label == null ||
                        formInstance.getFieldValue("experienceMin") == null ||
                        formInstance.getFieldValue("educationDetails")?.label == null ||
                        formDisabled
                      }
                      onClick={generateAIJobDescription}
                    >
                      Create JD with AI
                    </Button> */}
                  </div>
                  <Form.Item<TDemandFormField>
                    label="Job Description"
                    name="jobDescription"
                    rules={[
                      {
                        required: true,
                        message: "Please enter job description!",
                      },
                      {
                        max: 5000,
                        message: "Job description should be less than 5000!",
                      },
                    ]}
                  >
                    <Input
                      id="jobDescription"
                      htmlType="textarea"
                      placeholder="Please add job description here"
                      max={5000}
                    />
                  </Form.Item>
                </Flex>
              </div>
            </div>
            <div className="demand-form-section">
              <div className="demand-form-section-content">
                <Flex
                  className="additional-instructions"
                  justify="space-between"
                  gap={16}
                >
                  <Form.Item<TDemandFormField>
                    label="Additional Instructions"
                    name="additionalInstructions"
                    style={{ marginBottom: "16px" }}
                  >
                    <Input
                      id="additionalInstructions"
                      htmlType="textarea"
                      placeholder="Please add additional instructions here"
                      max={200}
                    />
                  </Form.Item>
                </Flex>
              </div>
            </div>
            {["open"].includes(formType) && (
              <div className="demand-form-section">
                <div className="demand-form-section-content">
                  <Form.Item<TDemandFormField>
                    label="Fulfillment Type"
                    name="fulfillmentType"
                    rules={[
                      {
                        required: true,
                        message: "Please select fulfillment type!",
                      },
                    ]}
                  >
                    <Select
                      id="fulfillmentType"
                      placeholder="Select Fulfillment Type"
                      options={dropdown.fulfillmentTypeList.filter(
                        (item) => item.label == EFulFillmentStatus.External
                      )} // remove filter condition in next release
                    />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>
          {formType == "open" && (
            <div className="demand-form-footer">
              <div className="demand-form-footer-section">
                <p className="text-wrapper-1">
                  Created By:{" "}
                  <span className="text-wrapper-2">
                    {saveDemandData?.submittedBy}
                  </span>
                </p>
              </div>
              <div className="demand-form-footer-section">
                <p className="text-wrapper-1">
                  Created Date:{" "}
                  <span className="text-wrapper-2">
                    {saveDemandData?.submittedDate
                      ? dayjs(saveDemandData?.submittedDate).format(
                          DateFormat_DDMMMYYYY
                        )
                      : ""}
                  </span>
                </p>
              </div>
              <div className="demand-form-footer-section">
                <p className="text-wrapper-1">
                  Effective Creation Date:{" "}
                  <span className="text-wrapper-2">
                    {saveDemandData?.effectiveCreatedDate
                      ? dayjs(saveDemandData?.effectiveCreatedDate).format(
                          DateFormat_DDMMMYYYY
                        )
                      : ""}
                  </span>
                </p>
              </div>
            </div>
          )}
          {["new", "draft"].includes(formType) && (
            <div className="demand-form-section-button">
              <div className="demand-form-section-content">
                <Flex gap={16} justify="space-between">
                  <Form.Item>
                    <Button
                      htmlType="button"
                      style={{ width: "100%" }}
                      disabled={leftButtonDisabled}
                      onClick={() => dispatch(setCloseDrawer(true))}
                    >
                      Close
                    </Button>
                  </Form.Item>
                  {/* <Form.Item>
                    <Button
                      htmlType="button"
                      disabled={true}
                      style={{ width: "248px" }}
                      onClick={() => saveSubmitForm(false)}
                    >
                      Save as Drafts
                    </Button>
                  </Form.Item> */}
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={
                        rightButtonDisabled ||
                        !openDemandPermission["f-create-open-demand"].write
                      }
                      style={{ width: "100%" }}
                      icon={
                        <SVGIcon
                          SVGElementIcon={ArrowRightIconComponent}
                          color="#929497"
                          colorActive="#ffffff"
                          active={formDisabled}
                          cursorPointer={rightButtonDisabled ? false : true}
                        />
                      }
                      onClick={() => saveSubmitForm(true)}
                      title={
                        !openDemandPermission["f-create-open-demand"].write
                          ? "Access Denied"
                          : rightButtonDisabled
                          ? "Please fill all mandatory fields"
                          : ""
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Flex>
              </div>
            </div>
          )}
          {formType == "open" && (
            <div className="demand-form-section-button">
              <div className="demand-form-section-content">
                <Flex gap={16} justify="space-between">
                  {(meta?.demandStatus?.fulfillmentType ===
                    EFulFillmentStatus.External ||
                    meta?.demandStatus?.fulfillmentType ===
                      EFulFillmentStatus.Internal) &&
                    meta?.demandStatus?.status == EDemandStatus.open &&
                    meta?.demandStatus?.subStatus !==
                      EDemandSubStatusExternal.On_Hold && (
                      <Form.Item>
                        <Button
                          htmlType="button"
                          style={{ width: "100%" }}
                          onClick={() => onHoldOrRelease(true)}
                          disabled={
                            !openDemandPermission["f-hold-demand"].write
                          }
                          title={
                            !openDemandPermission["f-hold-demand"].write
                              ? "Access Denied"
                              : ""
                          }
                        >
                          On Hold
                        </Button>
                      </Form.Item>
                    )}
                  {(meta?.demandStatus?.fulfillmentType ===
                    EFulFillmentStatus.External ||
                    meta?.demandStatus?.fulfillmentType ===
                      EFulFillmentStatus.Internal) &&
                    meta?.demandStatus?.status == EDemandStatus.open &&
                    meta?.demandStatus?.subStatus ==
                      EDemandSubStatusExternal.On_Hold && (
                      <Form.Item>
                        <Button
                          htmlType="button"
                          style={{ width: "100%" }}
                          onClick={() => onHoldOrRelease(false)}
                          disabled={
                            !openDemandPermission["f-release-hold-demand"].write
                          }
                          title={
                            !openDemandPermission["f-release-hold-demand"].write
                              ? "Access Denied"
                              : ""
                          }
                        >
                          Release
                        </Button>
                      </Form.Item>
                    )}
                  {meta?.demandStatus?.fulfillmentType ==
                    EFulFillmentStatus.External &&
                    meta?.demandStatus?.subStatus ==
                      EDemandSubStatusExternal.Hired && (
                      <Form.Item>
                        <Button
                          htmlType="button"
                          style={{ width: "100%" }}
                          onClick={() => setCloseByRmg(true)}
                          disabled={
                            !openDemandPermission["f-close-demand-by-rmg"].write
                          }
                          title={
                            !openDemandPermission["f-close-demand-by-rmg"].write
                              ? "Access Denied"
                              : ""
                          }
                        >
                          Close by RMG
                        </Button>
                      </Form.Item>
                    )}

                  {/* {!isRmAdmin && (
                    <Form.Item>
                      <Button
                        htmlType="button"
                        disabled={
                          leftButtonDisabled ||
                          !(
                            [
                              EFulFillmentStatus.Internal,
                              EFulFillmentStatus.External,
                            ] as string[]
                          ).includes(
                            meta?.demandStatus.fulfillmentType as string
                          )
                        }
                        style={{ width: "100%" }}
                        onClick={() => updateDemandStatus()}
                      >
                        Convert
                      </Button>
                    </Form.Item>
                  )} */}
                  <Form.Item>
                    <Button
                      htmlType="reset"
                      style={{ width: "100%" }}
                      onClick={() => setOpenCancellation(true)}
                      disabled={!openDemandPermission["f-cancel-demand"].write}
                      title={
                        !openDemandPermission["f-cancel-demand"].write
                          ? "Access Denied"
                          : ""
                      }
                    >
                      Cancel Demand
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={
                        rightButtonDisabled ||
                        !openDemandPermission["f-edit-save-demand"].write ||
                        initialDisable
                      }
                      style={{ width: "100%" }}
                      icon={
                        <SVGIcon
                          SVGElementIcon={SaveIconComponent}
                          color="#929497"
                          colorActive="#ffffff"
                          active={formDisabled}
                          cursorPointer={rightButtonDisabled ? false : true}
                        />
                      }
                      onClick={() => saveSubmitForm(false)}
                      title={
                        !openDemandPermission["f-edit-save-demand"].write
                          ? "Access Denied"
                          : rightButtonDisabled
                          ? "Please fill all mandatory fields"
                          : ""
                      }
                    >
                      Save & Close
                    </Button>
                  </Form.Item>
                  {meta?.demandStatus.fulfillmentType ===
                    EFulFillmentStatus.External &&
                    meta?.demandStatus?.subStatus ===
                      EDemandSubStatusExternal.Pending_Approval && (
                      <>
                        <Form.Item>
                          <Button
                            htmlType="reset"
                            style={{ width: "100%" }}
                            onClick={() => {
                              approveOrRejectOpenDemand(false);
                            }}
                            icon={
                              <SVGIcon
                                SVGElementIcon={XIcon}
                                active={true}
                                color="#000"
                              />
                            }
                            disabled={
                              !openDemandPermission["f-reject-demand"].write
                            }
                          >
                            Reject
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="reset"
                            style={{ width: "100%" }}
                            onClick={() => {
                              approveOrRejectOpenDemand(true);
                            }}
                            icon={
                              <SVGIcon
                                SVGElementIcon={Approve}
                                active={true}
                                color="#000"
                              />
                            }
                            disabled={
                              !openDemandPermission["f-approve-demand"].write
                            }
                          >
                            Approve
                          </Button>
                        </Form.Item>
                      </>
                    )}
                </Flex>
              </div>
            </div>
          )}
        </Form>
      </div>
      <Drawer
        title="Cancel Demand"
        open={openCancellation}
        onClose={() => setOpenCancellation(false)}
        placement="bottom"
        rootClassName="rejection-reason-drawer"
        destroyOnClose={true}
        children={
          <Confirmation
            data={meta}
            setModalOpen={setOpenCancellation}
            openCancel={setOpenCancellationModal}
          />
        }
      />
      <Drawer
        open={openCancellationModal}
        onClose={() => setOpenCancellationModal(false)}
        title="Reason for Cancellation"
        children={
          <RejectCancelReason
            type="cancel"
            fulfillment={null}
            onClose={(reason) => {
              setOpenCancellationModal(false);
              setOpenCancellation(false);
              cancelCurrentDemandForm(reason);
            }}
          />
        }
        placement="bottom"
        rootClassName="rejection-reason-drawer"
        destroyOnClose={true}
      />
      <Drawer
        open={closeByRmg}
        onClose={() => setCloseByRmg(false)}
        title="Reason for Closing"
        children={
          <RejectCancelReason
            type="rmgReasons"
            fulfillment={null}
            onClose={(reason) => {
              setCloseByRmg(false);
              closeDemandFormByRmg(reason);
            }}
          />
        }
        placement="bottom"
        rootClassName="rejection-reason-drawer"
        destroyOnClose={true}
      />
    </Fragment>
  );
};
