import React, { Dispatch, useMemo, useState } from "react";
import { ReactComponent as DownUpIcon } from "../../assets/down_up.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as FilterLineIcon } from "../../assets/filter-line.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { useAppSelector } from "../../hooks/redux-hooks";
import { RootState } from "../../redux/store";
import { EDatePickerType } from "../../views/enums";
import { ISort } from "../../views/interfaces";
import { Badge, Button, Drawer, Empty, SearchBar, Tooltip } from "../_wrapper";
import { AntdMenuProps, Dropdown } from "../_wrapper/Dropdown";
import { FilterAdvanced, FilterAdvancedProps } from "../Filter-Advanced";
import { FilterBasic, FilterBasicProps } from "../Filter-Basic";
import { SVGIcon } from "../SVG-Icon";
import "./Sub-Header.css";

export const welcomeMessage = {
  "Draft Demands":
    "You're currently viewing a list of draft demands. Explore the table below to find the tasks awaiting your attention",
  "Open Demands":
    "You're currently viewing a list of open demands. Explore the table below to find the tasks awaiting your attention",
  "All Employees":
    "You're currently viewing a list of all employees. Explore the table below to find the tasks awaiting your attention",
};

export interface SubHeaderProps {
  dashboardScreen?: boolean;
  filters?: FilterBasicProps[];
  advancedFilter?: {
    data: FilterBasicProps[];
    clearFilters: boolean;
    action: (apply?: boolean, clear?: boolean) => void;
  };
  advancedFilterCount?: number;
  showSearchBar?: boolean;
  setSearchString?: Dispatch<React.SetStateAction<string | null>>;
  showExportDataButton?: boolean;
  onClickExportData?: () => void;
  sortOption?: {
    data: ISort[];
    action: (sort: ISort | null) => void;
    selected: ISort | null;
  };
  positionStartDate?: FilterAdvancedProps["positionStartDate"];
  positionEndDate?: FilterAdvancedProps["positionEndDate"];
}

export const SubHeader: React.FC<SubHeaderProps> = ({
  dashboardScreen = false,
  filters = [],
  advancedFilter = {
    data: [],
    clearFilters: false,
    action: (apply, clear) => {},
  },
  advancedFilterCount = 0,
  showSearchBar = false,
  setSearchString,
  showExportDataButton = false,
  onClickExportData = () => {},
  sortOption = {
    data: [],
    action: (sort) => {},
    selected: null,
  },
  positionStartDate,
  positionEndDate,
}: SubHeaderProps) => {
  const [openAdvancedFilter, setOpenAdvancedFilter] = useState<boolean>(false);
  const firstName = useAppSelector(
    (state: RootState) => state.graph?.givenName
  );
  const headerTitle = useAppSelector(
    (state: RootState) => state.ui.headerTitle
  );

  const handleSortByMenuClick: AntdMenuProps["onClick"] = ({ key }) => {
    const sort = sortOption.data?.find((item) => item.columnName === key);
    sortOption.action(sort ? sort : null);
  };

  const items: AntdMenuProps["items"] = useMemo(() => {
    return sortOption.data?.map((item, idx) => {
      return {
        key: item.columnName,
        label: item.displayName,
      };
    });
  }, [sortOption.data]);

  const menuProps: AntdMenuProps = {
    items,
    onClick: handleSortByMenuClick,
  };

  const [datePickerType, setDatePickerType] = useState<string>(
    EDatePickerType.SpecificDate
  );

  return (
    <>
      <div className="sub-header">
        <div
          className={`welcome-message ${
            dashboardScreen ? "welcome-message-dashboard" : ""
          }`.trim()}
        >
          <span className="text-wrapper-1">
            {dashboardScreen && firstName ? (
              <>Good Day, {firstName} 👋🏼</>
            ) : (
              welcomeMessage[headerTitle as keyof typeof welcomeMessage]
            )}
          </span>
          {dashboardScreen ? (
            <span className="text-wrapper-2">
              {" "}
              Here is what happening today
            </span>
          ) : (
            <></>
          )}
        </div>
        <div className="frame">
          <div className="frame-wrapper-1">
            {filters &&
              filters?.length > 0 &&
              filters.map((_filter, idx) => (
                <FilterBasic key={idx} {..._filter} />
              ))}
            {advancedFilter && advancedFilter.data.length > 0 && (
              <div className="filter-advanced-button">
                <Badge
                  count={advancedFilterCount}
                  children={
                    <Button
                      size="small"
                      className="filter-advanced-button-btn"
                      style={{ width: "118px" }}
                      onClick={() => setOpenAdvancedFilter(true)}
                      icon={
                        <SVGIcon
                          SVGElementIcon={FilterLineIcon}
                          height={12}
                          width={12}
                        />
                      }
                    >
                      Advanced Filter
                    </Button>
                  }
                />
              </div>
            )}
          </div>
          <div className="frame-wrapper-2">
            {sortOption && sortOption.data.length > 0 && (
              <Dropdown
                trigger={["click"]}
                className="sort-by-dropdown"
                menu={menuProps}
                destroyPopupOnHide={true}
                dropdownRender={(menu) => (
                  <>
                    {items?.length > 0 ? (
                      <>
                        <div className="sort-by-dropdown-list">
                          {React.cloneElement(menu as React.ReactElement)}
                        </div>
                      </>
                    ) : (
                      <Empty />
                    )}
                  </>
                )}
              >
                <Button
                  htmlType="button"
                  type="text"
                  size="small"
                  className="sort-by-button"
                  icon={
                    <SVGIcon
                      SVGElementIcon={DownUpIcon}
                      width={12}
                      height={16}
                      active={sortOption.selected?.displayName ? true : false}
                    />
                  }
                >
                  <div className="sort-by-text">
                    Sort By:{" "}
                    <Tooltip title={sortOption.selected?.displayName ?? ""}>
                      <span
                        style={{
                          fontWeight: 600,
                          color: sortOption.selected?.displayName
                            ? "#fe5000"
                            : "#929497",
                        }}
                      >
                        {sortOption.selected?.displayName ?? "None"}
                      </span>
                    </Tooltip>
                  </div>
                </Button>
              </Dropdown>
            )}
            {showSearchBar && (
              <SearchBar
                rightIcon={SearchIcon}
                rootStyle={{ width: "224px" }}
                size="medium"
                setSearchString={setSearchString}
              />
            )}
            {showExportDataButton && (
              <Tooltip title="Export Data" placement="bottom">
                <div>
                  <Button
                    onClick={onClickExportData}
                    icon={
                      <SVGIcon SVGElementIcon={DownloadIcon} color="#ffffff" />
                    }
                  ></Button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <Drawer
        title="Advanced Filter"
        open={openAdvancedFilter}
        onClose={() => {
          advancedFilter.action(false);
          setOpenAdvancedFilter(false);
        }}
        width={560}
        children={
          <FilterAdvanced
            open={openAdvancedFilter}
            action={(apply, clear) => {
              advancedFilter.action(apply, clear);
              setOpenAdvancedFilter(false);
            }}
            clearFilters={advancedFilter.clearFilters}
            advancedFilterData={advancedFilter.data}
            positionStartDate={positionStartDate}
            positionEndDate={positionEndDate}
          />
        }
      />
    </>
  );
};
