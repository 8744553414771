import React, { useCallback, useEffect, useState } from "react";

import { SubHeader } from "../../components";
import { Tooltip } from "../../components/_wrapper";
import {
  AntdTableProps,
  Table,
  TableParams,
  defaultPagination,
} from "../../components/_wrapper/Table";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  defaultFilterData,
  exportEmployeeTableData,
  getEmployeeFilterData,
  getEmployeeTableData,
} from "../../redux/slice/employee";
import {
  getSelectedFilter,
  limitOffset,
  sort,
  updateFilterObject,
} from "../../utils";
import { SortByColumn } from "../../views/enums";
import {
  IEmployeeFilter,
  IEmployeeSelectedFilter,
  IEmployeeTable,
  ISort,
} from "../../views/interfaces";
import "./All-Employees.css";

export const defaultSelectedFilterData: IEmployeeSelectedFilter = {
  accounts: [],
  verticals: [],
  practices: [],
  projects: [],
  opportunities: [],
  locations: [],
  legalEntities: [],
  specializations: [],
  roles: [],
  levels: [],
  employeeStatus: [],
};

export interface AllEmployeesProps { }

export const AllEmployees: React.FC<AllEmployeesProps> = () => {
  const dispatch = useAppDispatch();
  const { filterData, sortData, tableData, totalCount } = useAppSelector(
    (state) => state.employee
  );

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: defaultPagination,
  });
  const [filter, setFilter] = useState<IEmployeeFilter>(defaultFilterData);
  const [selectedFilters, setSelectedFilter] =
    useState<IEmployeeSelectedFilter>(defaultSelectedFilterData);
  const [isSelectedFilterChanged, setIsSelectedFilterChanged] =
    useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<ISort | null>(null);

  const getTableData = useCallback(() => {
    dispatch(
      getEmployeeTableData({
        body: {
          sort: sort(tableParams.sortOrder, sortBy?.columnName),
          filter: selectedFilters,
          searchQuery: searchString ?? "",
        },
        params: {
          ...limitOffset(tableParams),
        },
      })
    );
  }, [
    dispatch,
    searchString,
    selectedFilters,
    sortBy?.columnName,
    tableParams,
  ]);

  useEffect(() => {
    if (JSON.stringify(filterData) === JSON.stringify(defaultFilterData))
      return;
    setFilter({ ...filterData });
  }, [filterData]);

  useEffect(() => {
    dispatch(getEmployeeFilterData());
  }, [dispatch]);

  useEffect(() => {
    if (isSelectedFilterChanged) {
      setSelectedFilter({
        accounts: getSelectedFilter(filter.accounts),
        practices: getSelectedFilter(filter.practices),
        projects: getSelectedFilter(filter.projects),
        verticals: getSelectedFilter(filter.verticals),
        opportunities: getSelectedFilter(filter.opportunities),
        locations: getSelectedFilter(filter.locations),
        legalEntities: getSelectedFilter(filter.legalEntities),
        specializations: getSelectedFilter(filter.specializations),
        levels: getSelectedFilter(filter.levels),
        roles: getSelectedFilter(filter.roles),
        employeeStatus: getSelectedFilter(filter.employeeStatus),
      });
      setIsSelectedFilterChanged(false);
    }
  }, [isSelectedFilterChanged, filter, dispatch]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  const onChangeFilter = (
    key: keyof IEmployeeFilter,
    label: any,
    value: string,
    checked: boolean
  ) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: prev[key].map((item) => {
          if (item.value === value && item.label === label) {
            return {
              ...item,
              tempChecked: checked,
              isUpdated: true,
            };
          }
          return item;
        }),
      };
    });
  };

  const filterApply = (
    apply: boolean = false,
    clear: boolean = false,
    key?: keyof IEmployeeFilter
  ) => {
    if (key) {
      setFilter((prev) => {
        return {
          ...prev,
          [key]: updateFilterObject(apply, prev[key]),
        };
      });
    } else {
      setFilter((prev) => {
        return {
          accounts: updateFilterObject(apply, prev.accounts),
          projects: updateFilterObject(apply, prev.projects),
          opportunities: updateFilterObject(apply, prev.opportunities),
          verticals: updateFilterObject(apply, prev.verticals),
          practices: updateFilterObject(apply, prev.practices),
          locations: updateFilterObject(apply, prev.locations),
          legalEntities: updateFilterObject(apply, prev.legalEntities),
          specializations: updateFilterObject(apply, prev.specializations),
          levels: updateFilterObject(apply, prev.levels),
          roles: updateFilterObject(apply, prev.roles),
          employeeStatus: updateFilterObject(apply, prev.employeeStatus),
        };
      });
    }
    if (apply) {
      setClearFilters(true);
      setIsSelectedFilterChanged(true);
    } else if (clear) {
      setFilter({ ...filterData });
      setClearFilters(false);
      setIsSelectedFilterChanged(true);
    } else {
      setIsSelectedFilterChanged(false);
    }
  };

  const exportTableData = () => {
    dispatch(
      exportEmployeeTableData({
        body: {
          sort: sort(tableParams.sortOrder, sortBy?.columnName),
          filter: selectedFilters,
          searchQuery: searchString ?? "",
        },
      })
    );
  };

  const tableColumns: AntdTableProps<IEmployeeTable>["columns"] = [
    {
      title: (
        <span>
          Employee ID <br /> Name
        </span>
      ),
      dataIndex: "employee",
      key: "employee",
      render: ({ internalId, id, name }) => (
        <>
          <span>{id}</span>
          <br />
          <Tooltip title={name} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{name}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Level <br /> Role
        </span>
      ),
      dataIndex: "levelRole",
      key: "levelRole",
      sorter: SortByColumn.level == sortBy?.columnName,
      render: ({ level, role }) => (
        <>
          <span>{level}</span>
          <br />
          <Tooltip title={role} placement="bottomRight">
            <span>{role}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Practice <br /> Sub-Vertical
        </span>
      ),
      dataIndex: "practiceVerticalSubVertical",
      key: "practiceVerticalSubVertical",
      sorter: (
        [SortByColumn.vertical, SortByColumn.practice] as string[]
      ).includes(sortBy?.columnName ?? ""),
      render: ({ practice, vertical, subVertical }) => (
        <>
          <Tooltip title={practice} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{practice}</span>
          </Tooltip>
          <br />
          <Tooltip
            title={
              subVertical && subVertical.length > 0 ? subVertical : vertical
            }
            placement="bottomRight"
          >
            <span>
              {subVertical && subVertical.length > 0 ? subVertical : vertical}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Specialization <br /> Skills
        </span>
      ),
      dataIndex: "specializationSkills",
      key: "specializationSkills",
      sorter: SortByColumn.specialization == sortBy?.columnName,
      render: ({ specialization, skills }) => (
        <>
          <Tooltip title={specialization} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{specialization}</span>
          </Tooltip>
          <br />
          {skills.length > 0 && (
            <Tooltip title={skills.join(", ")} placement="bottomRight">
              <span>{skills.join(", ")}</span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: SortByColumn.location == sortBy?.columnName,
      render: ({ name, onsiteOffshore }) => (
        <>
          <Tooltip title={name} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{name}</span>
          </Tooltip>
          <br />
          <span>{onsiteOffshore}</span>
        </>
      ),
    },
    {
      title: "Reporting Manager",
      dataIndex: "reportingManager",
      key: "reportingManager",
      render: (reportingManager) => (
        <>
          <Tooltip title={reportingManager} placement="bottomRight">
            <span>{reportingManager}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Alloc Type /<br />
          Status
        </span>
      ),
      dataIndex: "allocationTypeStatus",
      key: "allocationTypeStatus",
      render: ({ type, status }) => (
        <>
          <Tooltip title={type} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{type}</span>
          </Tooltip>
          <br />
          <Tooltip title={status} placement="bottomRight">
            <span>{status}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Allocation
          <br />
          Details
        </span>
      ),
      dataIndex: "allocationDetails",
      key: "allocationDetails",
      render: ({ project, percentage }) => (
        <>
          <span>{project}</span>
          <br />
          <span>{percentage} %</span>
        </>
      ),
    },
    {
      title: (
        <span>
          Allocation
          <br />
          Duration
        </span>
      ),
      dataIndex: "allocationDuration",
      key: "allocationDuration",
      sorter: SortByColumn.ageing == sortBy?.columnName,
      render: (agingSinceLastBilledDate) => (
        <>
          <span>{agingSinceLastBilledDate} days</span>
        </>
      ),
    },
  ];

  return (
    <div className="all-employee">
      <SubHeader
        advancedFilter={{
          action: (apply, clear) => filterApply(apply, clear),
          clearFilters: clearFilters,
          data: [
            {
              label: "Account",
              data: filter?.accounts,
              onChange: (label, value, checked) => {
                onChangeFilter("accounts", label, value, checked);
              },
            },
            {
              label: "Project",
              data: filter?.projects,
              onChange: (label, value, checked) => {
                onChangeFilter("projects", label, value, checked);
              },
            },
            {
              label: "Oppurtunity",
              data: filter?.opportunities,
              onChange: (label, value, checked) => {
                onChangeFilter("opportunities", label, value, checked);
              },
            },
            {
              label: "Vertical",
              data: filter?.verticals,
              onChange: (label, value, checked) => {
                onChangeFilter("verticals", label, value, checked);
              },
            },
            {
              label: "Practice",
              data: filter?.practices,
              onChange: (label, value, checked) => {
                onChangeFilter("practices", label, value, checked);
              },
            },
            {
              label: "Location",
              data: filter?.locations,
              onChange: (label, value, checked) => {
                onChangeFilter("locations", label, value, checked);
              },
            },
            {
              label: "Legal Entity",
              data: filter?.legalEntities,
              onChange: (label, value, checked) => {
                onChangeFilter("legalEntities", label, value, checked);
              },
            },
            {
              label: "Specialization",
              data: filter?.specializations,
              onChange: (label, value, checked) => {
                onChangeFilter("specializations", label, value, checked);
              },
            },
            {
              label: "Role",
              data: filter?.roles,
              onChange: (label, value, checked) => {
                onChangeFilter("roles", label, value, checked);
              },
            },
            {
              label: "Level",
              data: filter?.levels,
              onChange: (label, value, checked) => {
                onChangeFilter("levels", label, value, checked);
              },
            },
            {
              label: "Employee Status",
              data: filter?.employeeStatus,
              onChange: (label, value, checked) => {
                onChangeFilter("employeeStatus", label, value, checked);
              },
            },
          ],
        }}
        setSearchString={setSearchString}
        onClickExportData={exportTableData}
        showSearchBar={true}
        showExportDataButton={true}
        sortOption={{
          data: sortData,
          action: (sortBy) => setSortBy(sortBy),
          selected: sortBy,
        }}
      />
      <div className="all-employee-table">
        <Table
          columns={tableColumns}
          dataSource={tableData}
          tableParams={tableParams}
          setTableParams={setTableParams}
          totalCount={totalCount}
          expand={true}
        />
      </div>
    </div>
  );
};
