import React, { CSSProperties, useId } from "react";

import type { CheckboxOptionType, RadioChangeEvent } from "antd";
import { Radio as AntdRadio } from "antd";
import "./Radio.css";

export interface RadioProps {
  options: CheckboxOptionType<any>[];
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  style?: CSSProperties;
  id?: string;
  defaultValue?: string;
  disabled?: boolean;
}

export const Radio: React.FC<RadioProps> = ({
  options,
  value,
  onChange = (value) => {},
  className,
  style,
  id,
  defaultValue,
  disabled = false,
}) => {
  const newid = useId();
  const onSelectionChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
  };

  return (
    <AntdRadio.Group
      id={id ?? newid}
      onChange={onSelectionChange}
      value={value}
      className={className}
      style={style}
      defaultValue={defaultValue}
      disabled={disabled}
    >
      {options.map((option, idx) => {
        return (
          <AntdRadio key={idx + 1} value={option.value}>
            {option.label}
          </AntdRadio>
        );
      })}
    </AntdRadio.Group>
  );
};
