import React, { Dispatch } from "react";

import {
  Collapse as AntdCollapse,
  CollapseProps as AntdCollapseProps,
} from "antd";
import { ExpandIconPosition as AntdExpandIconPosition } from "antd/es/collapse/Collapse";
import { ReactComponent as DownIconComponent } from "../../../assets/down.svg";
import { ReactComponent as UpIconComponent } from "../../../assets/up.svg";
import { SVGIcon } from "../../SVG-Icon";
import "./Collapse.css";

export type { AntdCollapseProps, AntdExpandIconPosition };

export interface CollapseProps {
  items: AntdCollapseProps["items"];
  defaultActiveKey?: string | string[];
  bordered?: boolean;
  expandIconPosition?: AntdExpandIconPosition;
  keepDefaultExpandIcon?: boolean;
  destroyInactivePanel?: boolean;
  activeKey?: string[];
  setActiveKey: Dispatch<React.SetStateAction<string[]>>;
}

export const Collapse: React.FC<CollapseProps> = ({
  items,
  defaultActiveKey = ["1"],
  bordered = false,
  expandIconPosition = "end",
  keepDefaultExpandIcon = false,
  destroyInactivePanel = true,
  activeKey = ["1"],
  setActiveKey,
}) => {
  return (
    <AntdCollapse
      items={items}
      onChange={(key) => setActiveKey(key as string[])}
      defaultActiveKey={defaultActiveKey ? defaultActiveKey : ["1"]}
      activeKey={activeKey}
      bordered={bordered}
      expandIconPosition={expandIconPosition}
      expandIcon={
        keepDefaultExpandIcon
          ? undefined
          : ({ isActive }) =>
              isActive ? (
                <SVGIcon SVGElementIcon={UpIconComponent} />
              ) : (
                <SVGIcon SVGElementIcon={DownIconComponent} />
              )
      }
      destroyInactivePanel={destroyInactivePanel}
    />
  );
};
