import React, { CSSProperties } from "react";

import type { TabsProps as AntdTabsProps } from "antd";
import AntdTabs from "antd/es/tabs";
import "./Tabs.css";

export type TabPosition = "left" | "right" | "top" | "bottom";
export type { AntdTabsProps };

export interface TabsProps {
  items: AntdTabsProps["items"];
  activeTab?: string;
  setActiveTab?: (key: string) => void;
  centered?: boolean;
  tabPosition?: TabPosition;
  type?: AntdTabsProps["type"];
  style?: CSSProperties;
}

export const Tabs: React.FC<TabsProps> = ({
  items = [],
  activeTab = "1",
  setActiveTab = (key: string) => {},
  centered = false,
  tabPosition = "top",
  type = "line",
  style,
}) => {
  const onChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };

  return (
    <AntdTabs
      defaultActiveKey={activeTab}
      activeKey={activeTab}
      items={items}
      centered={centered}
      onChange={onChange}
      tabPosition={tabPosition}
      type={type}
      style={style}
      destroyInactiveTabPane={true}
    ></AntdTabs>
  );
};
