import React from "react";

import { Dayjs } from "dayjs";
import { Filters } from "../../views/enums";
import { Button } from "../_wrapper/Button";
import { FilterAdvancedExtra } from "../Filter-Advanced-Extra";
import {
  FilterAdvancedItem,
  FilterAdvancedItemProps,
} from "../Filter-Advanced-Item";
import "./Filter-Advanced.css";

export interface FilterAdvancedProps {
  open: boolean;
  action: (apply?: boolean, clear?: boolean) => void;
  advancedFilterData: FilterAdvancedItemProps[];
  clearFilters?: boolean;
  positionStartDate?: {
    show?: boolean;
    value?: Dayjs[];
    setValue?: React.Dispatch<React.SetStateAction<string[]>>;
  };
  positionEndDate?: {
    show?: boolean;
    value?: Dayjs[];
    setValue?: React.Dispatch<React.SetStateAction<string[]>>;
  };
}

export const FilterAdvanced: React.FC<FilterAdvancedProps> = ({
  open = true,
  action = (apply, clear) => {},
  advancedFilterData = [],
  clearFilters = false,
  positionStartDate = {
    show: false,
    value: [],
    setValue: () => {},
  },
  positionEndDate = {
    show: false,
    value: [],
    setValue: () => {},
  },
}) => {
  return (
    <div className="filter-advanced">
      <div className="filter-advanced-content">
        <div className="filter-advanced-list">
          {advancedFilterData.map((aData, idx) => (
            <FilterAdvancedItem key={idx} {...aData} open={open} />
          ))}
          {positionStartDate && positionStartDate.show && (
            <FilterAdvancedExtra
              headerTitle={Filters.positionStartDate}
              value={positionStartDate.value}
              setValue={positionStartDate.setValue}
            />
          )}
          {positionEndDate && positionEndDate.show && (
            <FilterAdvancedExtra
              headerTitle={Filters.positionEndDate}
              value={positionEndDate.value}
              setValue={positionEndDate.setValue}
            />
          )}
        </div>

        <div className="filter-advanced-content-footer">
          <Button
            style={{ width: "248px" }}
            onClick={() => action(false, clearFilters)}
          >
            {clearFilters ? "Clear Filter" : "Back"}
          </Button>
          <Button
            type="primary"
            style={{ width: "248px" }}
            onClick={() => action(true)}
          >
            Apply Filter
          </Button>
        </div>
      </div>
    </div>
  );
};
